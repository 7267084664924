import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'

//^APPLICATION PENDING
export const appContainer = { 
    backgroundColor: white,
    width: '70vw',
    height: '65vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}
export const headerTextSubBlock = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '6vh', 
    marginTop: '1%',
    color: black
}
export const headerSub = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '4.5vh', 
    color: black,
    marginTop: '-3%',
    textAlign: 'center'
}
export const paragraph = { 
    fontFamily: 'ApercuProRegular',
    fontSize: '2.5vh', 
    color: black,
    lineHeight: '130%',
    width: window.innerWidth > 1200 ? '75%' :  '70%',
    marginTop: '-.5%',
    textAlign: 'center'
}
export const bigCheckLarge = { 
    width: '7vw',
    height: '11vh', 
    marginBottom: 0,
    marginTop: '-2%',
}


//^APPLICATION REJECTED / ACCEPTED
export const notiContainer = { 
    backgroundColor: white,
    width: '70%',
    height: '55%',
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
    flexDirection: 'row',
    opacity: 1,
    boxShadow: '2, 0, 4, rgba(0, 0, 0, .25)',
    zIndex: 2,
}
export const textFlexer = { 
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100%',
    minWidth: '60%',
}
export const greenSide = { 
    backgroundColor: 'rgba(120, 153, 137, .25)',
    minWidth: '20%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
}
export const headerTextApp = { 
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '5vh',
    marginLeft: '10%'
}

export const subHeaderTextApp = { 
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '3vh',
    lineHeight: '110%',
    marginLeft: '10%',
    marginTop: '-1%',
    width: '60%'
}

export const subHeaderTextReject = { 
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '3vh',
    lineHeight: '110%',
    marginLeft: '10%',
    marginTop: '-2%',
    width: '80%'
}

export const paragraphApp = {
    color: black,
    lineHeight: '140%',
    fontSize: '2.2vh', 
    width: '80%',
    marginLeft: '10%',
    marginTop: '-1%',
}