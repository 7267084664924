import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'

export const resetSuccessContainer = { 
    backgroundColor: white,
    width: '40%',
    height: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}

//Todo: Hacky sack solution, have to make height a multiplication factor next time
export const bigCheckLarge = { 
    width: 125,
    height: 125
}
export const bigCheckMedium = { 
    width: 115,
    height: 115
}
export const bigCheckSmall = { 
    width: 95,
    height: 95
}
export const resetTitle = {
    fontFamily: 'PPNeueMontrealBold', 
    color: black, 
    textAlign: 'center',
    fontSize: window.innerWidth > 1300 ? 32 : 28,  
    marginTop: '5%'
}

export const resetSub = {
    fontFamily: 'ApercuProRegular', 
    fontSize: window.innerWidth > 1300 ? 18 : 16, 
    color: black, 
    textAlign: 'center',
    width: window.innerWidth > 1300 ? '85%' : '90%', 
    lineHeight: '140%', 
    marginTop: '-2%'
}
export const backToLoginButton = { 
    fontFamily: 'ApercuProBold',
    fontSize: window.innerWidth > 1300 ? 18 : 16, 
    color: primary,
    textAlign: 'center',
    display: 'inline',
    marginTop: '2.5%',
    cursor: 'pointer'
}