import {background, primary, secondary, orange, altGray, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'


export const container = {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    marginTop: 0,
    marginBottom: 0,
    overflowY: 'scroll',
    flexDirection: 'column'
}
export const greenBackground = { 
    margin: 0,
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    marginBottom: 0,
    background: 'radial-gradient(circle at center, #F5F5F5, #EFEFEF, #E8F5E9), linear-gradient(to bottom, #F5F5F5, #EFEFEF)', // Added hint of green
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
}
export const greenContainer = { 
  
    backgroundColor: 'rgba(120, 153, 137, .2)',
    margin: 0,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    marginBottom: 0
      
}
export const header = { 
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 'auto',
    minHeight: '10vh',
    
}

//^Internal App Styles
export const containerNavPresent = {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    display: 'flex',
    margin: 0,
    marginTop: 0,
    marginBottom: 0,
    
}
export const smallWhiteContainerNavPresent = {
    margin: 0,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: '20%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white,
    width: '80%',
    minWidth: '80%',
    minHeight: '100vh',
    height: '100vh',
}
export const headerTextNavPresent = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '6vh', 
    color: black,
    marginLeft: '2.5%'
}
  
export const headerSubNavPresent = { 
    fontFamily: 'PPNeueMontrealMedium',
    fontSize: '2.8vh',
    color: black,
    width: '90%',
    lineHeight: '140%',
    marginTop: '-1%',
    marginLeft: '2.5%'
} 

export const montrealSubHeader = {

    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2.5vh',
    textDecoration: 'underline',
    letterSpacing: '110%'
    
        
}