import React from 'react'
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, altGray, darkGray, textB} from '../styles/Colors'
import { useNavigate, useLocation } from 'react-router'
import StickyNav from '../components/StickyNav'
import CustomButton from '../components/CustomButton'
import { containerNavPresent, headerTextNavPresent, smallWhiteContainerNavPresent } from '../styles/Globals'
import { middleDiv, subHeader, paragraph } from '../styles/ListingCompleteStyles'

export default function ListingAppComplete() {

    //^CONSTANTS (NAVIGATION)
    const navigate = useNavigate()
    const location = useLocation()
    return (
    <div style = {containerNavPresent}>
         <StickyNav tab = 'listing' userUID = {location.state.uid} pfpSrc={location.state.pfp} disabled = {false}></StickyNav>
        <div style = {smallWhiteContainerNavPresent}>
            <h1 style = {headerTextNavPresent}>Piece Listing Form</h1>
              
            <div style = {middleDiv}> 
                <h2 style = {subHeader}>Congratulations, your application has been submitted!</h2>
                <p style = {paragraph}>There is nothing left to do on your end <br /> We will review your application and notify you when its been approved.</p>
                <CustomButton type={'dark'} text={"Continue"} callbackfunction = {() => navigate('/Home', {state: {uid: location.state.uid, pfp: location.state.pfp}})} height = {'7%'} width = {'18%'} mTop= {'2.5%'} wide = {false}> </CustomButton>
            </div>

        </div>
       

        
    </div>
    )
}

//^STYLES (CONTAINER)

