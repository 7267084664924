import React from 'react'
import { useEffect, useState } from 'react';
import { getUser } from '../database/UserFunctions';
import { useNavigate, Link, useLocation} from "react-router-dom"
import StickyNav from '../components/StickyNav';
import { montrealSubHeader, containerNavPresent, headerTextNavPresent, smallWhiteContainerNavPresent, container } from '../styles/Globals';

export default function Metrics() {

  const navigate = useNavigate();
  const location = useLocation();
  const [profile, setProfile] = useState({
    pfp: ""
  })

  useEffect(() => {
    async function fetchData() {
        let userModel = await getUser(location.state.uid)
        setProfile(userModel)
        
        // Now we calculate which page we are on and set the style
  }
    fetchData()
  }, []);

  return (
    <div style = {containerNavPresent}>
      <StickyNav tab = 'metrics' userUID = {location.state.uid} pfpSrc={location.state.pfp} disabled = {false}></StickyNav>


      <div style = {smallWhiteContainerNavPresent}> 

        <h1 style = {headerTextNavPresent}>Metrics</h1>
        <div style = {{width: '100%', display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}> 
          <h2 style = {montrealSubHeader}>Coming Soon!</h2>
        </div>
      </div>
    </div>
    
  )
}

