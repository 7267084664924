import { useNavigate, useLocation, useResolvedPath} from "react-router-dom"
import React, { useMemo } from "react";
import CustomButton from '../components/CustomButton';
import { useEffect, useState, useRef } from 'react';
import { getUser } from '../database/UserFunctions';
import { rememberCredentialsWithExpiration, getCurrentUserUID, emailLogin } from "../database/AuthFunctions";
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, darkGray} from '../styles/Colors'
import checkMark from '../assets/lilCheck.svg'
import eyeOpen from '../assets/eyeOpen.svg'
import eyeClosed from '../assets/eyeClosed.svg'
import {loginContainer, title, subText, labelTextOne, labelTextTwo, labelTextThree, passwordFlexer} from '../styles/LoginStyles'
import {passwordVisibilityChanger, rememberMeDiv, loginHelpDiv, rememberMeText, forgotPasswordText, noAccountText, noAccountTextUnderline, disclaimer, inputFlex} from '../styles/LoginStyles'
import { greenContainer } from "../styles/Globals";
import animationData from '../assets/loader.json'
import Lottie from 'lottie-react';
export default function ArtistSignup() {

  //^CONSTANTS:
  const navigate = useNavigate();
  const[email, setEmail] = useState('')
  const[password, setPassword] = useState('')
  const emailBox = useRef()
  const passwordBox= useRef()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const checkMarkRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [isErrorPass, setIsErrorPass] = useState(false)
  const [isErrorEmail, setIsErrorEmail] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)


  //& Authenticate user with firebase and navigate them to the correct screen depending on their application status 
  const login = async () => {

    setIsLoading(true)
    //! Should we remember the user for a period of time?
    if (rememberMe) await rememberCredentialsWithExpiration('expirationDate', 1209600000)
    let res = await emailLogin(email, password)
    
    //! Successful login 
    if(res.success) {
      console.log("user logged in!")
      sessionStorage.setItem('authenticated', 'true');
      let user = await getUser(res.uid) //* fetch user from firebase 
      
      setIsLoading(false)
      switch (user.artistApplicationStatus) {
        case undefined:
            navigate("/Apply", {state: {uid: res.uid}})
            break;
      
        case "pending":
          navigate("/ApplicationPending")
          
        case "rejected":
          navigate("/ApplicationRejected")
          break;
        
        case "accepted":
          navigate("/ApplicationAccepted", {state: {uid: res.uid}})
          break;

        case "onboarded":
          navigate("/Home", {state: {uid: res.uid, pfp: user.pfp}})
          break;

        default:
          break;
      }

    //! Error in Login 
    } 
    else { 
      setIsLoading(false)
      if (res.type == 'wrongPassword') setIsErrorPass(true)
      else if (res.type == 'invalidEmail') setIsErrorEmail(true)
      else {  
        setIsErrorPass(true)
        setIsErrorEmail(true) 
      }
    }
  }

  

  //^ STYLE CHANGES FOR TEXTBOX 

  const textBoxOutlineEmail = useMemo(() => {
    return { 
      width: '100%',
      height: '25%',
      outline: 'none',
      borderWidth: isErrorEmail ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      borderColor: isErrorEmail ? red : lightGray,
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderStyle: 'solid',
      marginTop: '1%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh', 
      textIndent: '10px',
      color: black,
      marginBottom: 0
  }
  
 }, [isErrorEmail])
const passwordBoxOutline = useMemo(() =>  {
  return {
    width: '100%',
    height: '100%',
    outline: 'none',
    borderWidth: isErrorPass ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
    borderColor: isErrorPass ? red : lightGray,
    borderStyle: 'solid',
    backgroundColor: 'rgba(120, 153, 137, .1)',
    fontFamily:'ApercuProRegular',
    fontSize: '1.8vh', 
    textIndent: '10px',
    color: black,
    marginBottom: 0,
    zIndex: 1
  }
}, [isErrorPass])
  const checkmarkBox = useMemo(() => {
  return { 
    width: '1.05vw', 
    height: '1.8vh' , 
    minWidth: '1.05vw', 
    minHeight: '1.8vh',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: rememberMe ? green : 'transparent', 
    borderWidth: '1.5px 1.5px 1.5px 1.5px',
    borderStyle: 'solid',
    borderColor: rememberMe ? green : darkGray,
  }
}, [rememberMe])

  const checkmarkFlic = useMemo(() => { 
    return { 
      width: '100%', 
      height: '100%', 
      display: rememberMe ? 'block' : 'none', 
    }

  }, [rememberMe])
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData
   
};
  return (

    <div style = {greenContainer}>
        
      <div style = {loginContainer}> 

          <h1 style = {title}>Welcome New Artist</h1>
          <p style = {subText}>We require artists to create their account with our mobile application first. </p>

          <div style = {inputFlex}>
            <label style = {labelTextOne}>Email Address</label>
            <input type = "text" value={email} onChange = {(event) => setEmail(event.target.value)} placeholder='Enter your email' onClick = {() => setIsErrorEmail(false)} style = {textBoxOutlineEmail} ref = {emailBox}/>

            <label style = {labelTextTwo}>Password</label>
            <div style = {passwordFlexer}> 
            <input type = {passwordVisible ? "text" : "password"} value={password} onChange = {(event)=> setPassword(event.target.value)} placeholder='Enter your password' onClick = {() => setIsErrorPass(false)} style = {passwordBoxOutline} ref = {passwordBox}/>
            {passwordVisible ? 
              <img  style = {passwordVisibilityChanger} src = {eyeOpen} onClick = {() => setPasswordVisible(false)}/>
              : 
              <img  style = {passwordVisibilityChanger} src = {eyeClosed} onClick = {() => setPasswordVisible(true)}/>
            }
            </div>
            
            <label style = {labelTextThree}>It must be a combination of 8 letters, numbers, and symbols</label>
          </div>

        <div style = {loginHelpDiv}>
          
          <div style = {rememberMeDiv}> 
            <div style = {checkmarkBox} onClick= {() => setRememberMe(!rememberMe)}> 
              <img ref = {checkMarkRef} style = {checkmarkFlic} src = {checkMark} />  
            </div>
            <p style = {rememberMeText}>Remember me</p>
          </div>
          <p style={forgotPasswordText} onClick = {() => navigate('/ForgotPassword')}>Forgot Password?</p>
          
        </div>
    
        <CustomButton type={'dark'} text={"Log In"} callbackfunction = {() => login()} height = {'5vh'} width = {'70%'}  mTop = {'5.5%'} wide = {true} loading = {isLoading}> </CustomButton>

        <a href = "https://bit.ly/unveilartbetageneral" style = {noAccountText}>*No account yet? <h1 style = {noAccountTextUnderline}>Sign Up</h1></a>

      </div>

     
      
      <p style = {disclaimer}>Unveil's investment platform is currently hosting a closed beta with a talented group of investors and artists. If you are interested
        in this platform and would like to join this beta, please reach out to partners@unveil.group
      </p>

    </div>
    
  );
}




