import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
import backgroundLandingImage from '../assets/backgroundLandingImage.png'

export const divToHide = { 
    background: white,
    alignItems: 'center',
    // justifyContent: 'center',
    overflowX: 'hidden',
    overflowY: 'scroll',
    margin: 0,
    marginBottom: 0,
    padding: 0,
    zIndex: -2,
    minWidth: '100%',
   
  }

export const greenBackgroundImage = {
  backgroundImage: `url(${backgroundLandingImage})`,
  width: '100vw',
  marginTop: '0', 
  height: '45vh',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowY: 'visible',

}
export const bigLandingHeaderText = {
  fontFamily: 'PPNeueMontrealBold', 
  fontSize: '8vh', 
  olor: black,  
  marginLeft: '5%',
  marginRight: 0,

}
export const mobileMockup = { 
  width: '48vw',
  height: '76vh',
  marginTop: '22%',
}
export const tagline = {
  fontFamily: 'ApercuProRegular', 
  color: black, 
  fontSize: 20, 
  width: '35%', 
  lineHeight: '140%', 
  marginLeft: '5%', 
  marginTop: '2.5%'
}
export const topButtonFlexer = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '2%', 
  marginLeft: '5%',
  width: '28%',
  justifyContent: 'space-between'
}

export const headerTextSubBlock = { 
  fontFamily: 'PPNeueMontrealBold',
  fontSize: '6vh', 
  color: black,
  textAlign: 'center',
  marginTop: '10%'
}
export const subTextBlock = {
  fontFamily: 'ApercuProMedium', 
  fontSize:'2.4vh', 
  color: black, 
  letterSpacing: '.5', 
  textAlign: 'center',
  marginTop: '-1%'
}
export const subBlockFlex = { 
  display: 'flex', 
  flexDirection: 'row',
  alignItems: 'center',
  width: '90%',
  marginLeft: 'auto',
  marginRight: 'auto', 
  marginTop: '2.5%', 
  justifyContent: 'space-between'
}
export const subMissionHeader = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2vh', 
    color: black,
    marginTop: '3%'
} 

export const missionCards = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}
export const missionText = {
    fontFamily: 'ApercuProRegular',
    fontSize: '1.5vh', 
    color: black,
    lineHeight: '140%',
    width: '80%',
    textAlign: 'center',
    marginTop: '-1%'
}

export const mobileFlex = { 
  width: '90%',
  height: '60vh',
  marginTop: '10%',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const whyUnveil = {
  color: black,
  fontFamily: 'PPNeueMontrealBold',
  fontSize: '6vh', 
}
export const accentUnveil = {
  color: primary,
  fontFamily: 'PPNeueMontrealBold',
  fontSize: '6vh',
}
export const whySubText = { 
  fontFamily: 'ApercuProMedium',
  fontSize: '2vh',
  width: '100%',
  color: black,
  lineHeight: '140%',
  marginTop: '1%'
}

export const artGalleryPlaceholder = { 
  width: '30vw',
  height: '55vh',
  marginRight: '5%',
  marginBottom: 0,
  boxShadow: '0px 10px 10px 0px #000000',
  borderColor: lightGray,
  borderWidth: '1px 1px 1px 1px',
  borderStyle: 'solid',
  boxShadow: '0px -5px 10px 0px rgba(120, 153, 137, .1)'
}


export const artistCards = { 
    backgroundColor: white,
    borderColor: 'rgba(193, 199, 205, .2)',
    borderWidth: '1px 1px 1px 1px',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    width: '19vw',
    height: '20vh',
    boxShadow: '2px 4px 4px 0px rgba(0, 0, 0, .25)',
    boxShadow: '2px -5px 10px 0px rgba(120, 153, 137, .1)',
  
  }
export const subArtistHeader = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2.5vh',
    color: black,
    paddingTop: 15,
    marginTop: '1%',
    marginLeft: '5%'
  }
  
export const artistText = { 
    fontFamily: 'ApercuProRegular',
    fontSize: '1.8vh',  
    color: black,
    lineHeight: '140%', 
    marginLeft: '5%',
    marginTop: '-1%',
    paddingRight: '2%', 
    
}



  
export const readyToGetStarted = {
  fontFamily: 'PPNeueMontrealBold', 
  fontSize: '4vh', 
  color: black, 
  textAlign: 'center', 
  marginTop: '2.5%'
}


export const subTextArtistAction = {
  fontFamily: 'ApercuProMedium', 
  fontSize: '2.5vh', 
  color: black, 
  letterSpacing: '.5', 
  textAlign: 'center',
  
}

export const artistActionButtonFlex = {
  display: 'flex', 
  flexDirection: 'horizontal', 
  justifyContent: 'space-between', 
  alignItems: 'center',
  marginTop: '2.5%',
  width: '28%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '10%'
}

//^STYLES FOR ACCEPTED AND REJECTED NOTIFICATIONS
export const notiContainer = { 
    backgroundColor: white,
    width: '70%',
    height: '50%',
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
    flexDirection: 'row',
    opacity: 1,
    boxShadow: '2, 0, 4, rgba(0, 0, 0, .25)',
    zIndex: 2,
  
  
  }
export const greenBackground = { 
    backgroundColor: '#DDE5E1',
    width: '40%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  }
export const headerText = { 
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '6vh',
    marginLeft: '10%',
    marginTop: '5%'
  }
  
export const subHeaderText = { 
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '3vh',
    lineHeight: '110%',
    marginLeft: '10%',
    marginTop: '-1%',
    width: '60%'
  }
  
export const subHeaderTextR = { 
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '3vh',
    lineHeight: '110%',
    marginLeft: '10%',
    marginTop: '-1%',
    width: '70%'
  }
  
export const paragraph = {
    color: black,
    lineHeight: '140%',
    fontSize: '1.5vh',
    width: '80%',
    marginTop: '5%',
    marginLeft: '10%'
  }
export const paragraphR = {
    color: black,
    lineHeight: '140%',
    fontSize: '1.5vh',
    width: '70%',
    marginTop: '5%',
    marginLeft: '10%'
  }
  
export const textButton = {
    color: accentColor,
    textDecoration: 'underline',
    fontFamily: 'ApercuProMedium',
    fontSize: '2vh',
    marginLeft: '10%',
    cursor: 'pointer'
  
  }