import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'

export const contentContainer = {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    minWidth: '95%',
    marginLeft: '2.5%',
    height: '85%',
    minHeight: '85%'
}
//^ Content FAR LEFT (IMAGES AND DESCRIP)
export const contentOne = {
    height: '100%',
    minHeight: '100%',
    width: '35%',
    minWidth: '35%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
}
export const largeImageContainer = {
    minWidth: '100%',
    width: '100%',
    minHeight: '50%',
    height: '50%',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}
export const imgPreviewLarge = { 
    width: '100%',
    height: '100%',
    minWidth: '100%',
    minHeight: '100%',
    margin: 0,
    padding: 0,
    
}
export const imgSelector = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '5%',
    width: '100%',
    minWidth: '100%',
    height: '12%',
    minHeight: '12%',
}
export const imageContainer = {
    width: '22.75%',
    minWidth: '22.75%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    marginLeft: '3%',
    marginRight: 0,
    justifyContent: 'center',
    alignItems: 'center'
}
export const emptyImageContainer = {
    width: '22.75%',
    minWidth: '22.75%',
    height: '100%',
    minHeight: '100%',
    marginLeft: '3%',
    marginRight: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(120, 153, 137, .2)'
}
export const imageContainerFirst = {
    width: '22.75%',
    minWidth: '22.75%',
    height: '100%',
    minHeight: '100%',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export const imgPreviewSmall = { 
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
}

export const artPlaceholderSmall = {
    width: '25%',
    height: '25%'
}


export const genreScroll = { 
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    minWidth: '80%',
    justifyContent: 'flex-start',
    marginTop: '7.5%',
    marginBottom: 0,
    height: '4.5%',
    minHeight: '4.5%'
    
}
export const genreButtonBoxSmall = { 
    fontFamily: 'ApercuProMedium',
    fontSize: '1.6vh',
    color: accentColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minWidth: '35%',
    width: '35%',
    maxWidth: '35%',
    minHeight: '100%',
    height: '100%',
    marginRight: '2.5%',
    backgroundColor: white,
    borderColor: accentColor,
    borderWidth: '2px, 2px, 2px, 2px',
    borderStyle: 'solid',
    borderRadius: 28,
    cursor: 'pointer'
}
export const pieceDescription = {
    fontFamily: 'ApercuProRegular',
    fontSize: '1.6vh', 
    color: black,
    width: '130%',
    height: '25%',
    minHeight: '25%',
    maxHeight: '25%',
    lineHeight: '140%',
    overflowY: 'scroll',
    marginTop: '5%'
}
export const quotes = { 
    fontFamily: 'ApercuProBoldItalic',
    fontSize: '1.8vh',
    color: black,
    fontWeight: 800
}

//^CONTENT TWO: PIECE FACTS

export const contentTwoMetrics = {
    height: '50%',
    minHeight: '50%',
    marginLeft: '2.5%',
    width: '15%',
    minWidth: '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}

export const pieceFactBox = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
}
export const subHeader = {
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2vh',
    color: black,
}
export const pieceFact = {
    fontFamily: 'PPNeueMontrealRegular',
    fontSize: '1.8vh',
    color: black,
    lineHeight: '100%',
    fontWeight: 100,
    marginTop: '-3%'
}

//^CONTENT THREE: GRAPH & Campaign 
export const contentThree = { 
    height: '100%',
    minHeight: '100%',
    width: '42.5%',
    minWidth: '42.5%',
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderRightWidth: '0px',
    borderTopWidth: '0px',
    borderBottomWidth: '0px',
    borderLeftWidth: '4px',
    borderColor: '#EBEBEB'  
}
export const subHeaderFunding = {
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2vh',
    color: black,
    marginLeft: '20%'
}
export const graphTextAligner = {
    height: '80%',
    display: 'flex',
    marginLeft: '20%',
    flexDirection: 'row',
    marginTop: '5%',
    justifyContent: 'flex-start',
}
export const axisMap = { 
    height: '100%',
    minHeigth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginRight: '2.5%',
    marginLeft: 0
}
export const yAxisStyle = { 
    fontFamily: 'PPNeueMontrealMedium',
    fontSize: '1.5vh', 
    color: black,
    fontWeight: 700
}
export const timeRemaining = { 
    marginLeft: '20%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    justifyContent: 'flex-start',
    marginTop: '5%'
}
export const outerAxiis = {
    borderWidth: '0px 0px 2px 2px',
    borderColor: black,
    borderStyle: 'solid',
    height: '100%',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'

}



