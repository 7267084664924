import { useRef, useState, useMemo} from 'react';
import { getAuthToken } from '../database/AuthFunctions';
import { useNavigate, useLocation} from "react-router-dom"
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, altGray, darkGray, textB} from '../styles/Colors'
import logoBlack from '../assets/logoBlack.png'
import logoGreen from '../assets/logoGreen.png'
import CustomButton from '../components/CustomButton'
import { container, header, greenBackground  } from "../styles/Globals";
import {inputFlex, inputRowFlexer, loginContainer, rowFlexElement, subText, title,  textBoxOutline, labelText, textBoxOutlineLong, optional } from '../styles/ApplyStyles';

export default function Apply() {


  
  //^CONSTANTS:
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [socialMediaAccount, setSocialMediaAccount] = useState('')
  const [description, setDescription] = useState('')
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const phoneRef = useRef()
  const websiteRef = useRef()
  const socialMediaRef = useRef()
  const descriptionRef = useRef()
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const [errorFirstName, setErrorFirstName] = useState(false)
  const [errorLastName, setErrorLastName] = useState(false)
  const [errorPhone, setErrorPhone] = useState(false)
  const [errorSocialMediaAccount, setErrorSocialMediaAccount] = useState(false)

  //&Attempt to send artist application and handle all necessary errors 
  async function sendApplication() {
    setIsLoading(true)
    var headers = new Headers();
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", await getAuthToken());

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        uid: location.state.uid,
        name: firstName + lastName,
        phone: phone,
        website: website,
        socials: socialMediaAccount,
        optionalQuestion: description
      }),
      redirect: 'follow'
    }

    let res = await fetch("https://us-central1-artisynapp.cloudfunctions.net/artists/submitApplication", requestOptions)

    if(res.status == 200) {
      setIsLoading(false)
      navigate("/ApplicationPending", {state: {uid: location.state.uid}})
    } else {
        setIsLoading(false)
        //* Handle empty box error checking 
        if (firstName  == '') setErrorFirstName(true)
        if (lastName == '') setErrorLastName(true)
        if (phone == '') setErrorPhone(true)
        if (socialMediaAccount == '') setErrorSocialMediaAccount(true)
        return
    }
  }
  //& Have contact us button go to email 
  const contactUs = () => { 
    window.location.href = "mailto:ops@unveil.group"
  }
  

  //^ STYLE CHANGES FOR TEXTBOX 
  const textBoxOutlineFirstName = useMemo(() => {
    return { 
      width: '100%',
      height: '85%',
      outline: 'none',
      borderWidth: errorFirstName ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderColor: errorFirstName ? red : lightGray,
      borderStyle: 'solid',
      marginTop: '2.5%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh',
      textIndent: 10,
      color: black
    }
  }, [errorFirstName])

  const textBoxOutlineLastName = useMemo(() => {
    return { 
      width: '100%',
      height: '85%',
      outline: 'none',
      borderWidth: errorLastName ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderColor: errorLastName ? red : lightGray,
      borderStyle: 'solid',
      marginTop: '2.5%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh',
      textIndent: 10,
      color: black
    }
  }, [errorLastName])
  const textBoxOutlinePhone = useMemo(()=> { 
    return { 
      width: '100%',
      height: '9%',
      outline: 'none',
      borderWidth: errorPhone ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      borderColor: errorPhone ? red : lightGray,
      borderStyle: 'solid',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      marginTop: '1%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh',
      textIndent: 10,
      color: black,
    }
  }, [errorPhone])
  const textBoxOutlineMedia = useMemo(()=> { 
    return { 
      width: '100%',
      height: '9%',
      outline: 'none',
      borderWidth: errorSocialMediaAccount ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      borderColor: errorSocialMediaAccount ? red : lightGray,
      borderStyle: 'solid',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      marginTop: '1%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh',
      textIndent: 10,
      color: black,
    }
  }, [errorSocialMediaAccount])
      
  return (
      <div style = {container}>
         
        <div style = {header}>
              <img src = {logoBlack} style = {{width: '5%', height: '5%'}} />
              <CustomButton type={'dark'} text={"Contact Us"} callbackfunction = {() => contactUs()} height = {'5vh'} width = {'12vw'}> </CustomButton>
          </div>  

        <div style = {greenBackground}>
          <div style = {loginContainer}>
            <h1 style = {title}>Apply to Unveil Artist</h1>
            <p style = {subText}>To become an artist and list your work with us here at Unveil, you'll need to first fill out the form below. We look forward to seeing your work!</p>
            <div style = {inputFlex}> 
              <div style = {inputRowFlexer}> 
                <div style = {rowFlexElement}> 
                  <label style = {labelText}>First Name</label>
                  <input type = "text" value={firstName} onChange = {(event) => setFirstName(event.target.value)}onClick = {() => setErrorFirstName(false)}  placeholder = "First name..." ref = {firstNameRef} style = {textBoxOutlineFirstName}/>
                </div>
                <div style = {rowFlexElement}>
                  <label style = {labelText}>Last Name</label>
                  <input type = "text" value={lastName} onChange = {(event) => setLastName(event.target.value)} onClick = {() => setErrorLastName(false)} placeholder = "Last name..." ref = {lastNameRef} style = {textBoxOutlineLastName}/>
                </div>
              </div>
              
              <label style = {labelText}>Phone Number</label>
              <input type = "text" placeholder = "(***) ***-****"  value={phone} onChange = {(event) => setPhone(event.target.value)} onClick = {() => setErrorPhone(false)} ref = {phoneRef} style = {textBoxOutlinePhone}/>
              <label style = {labelText}>Artist Website <span style = {optional}>(optional)</span></label>
              <input type = "text" placeholder = "www.example.com"  value={website} onChange = {(event) => setWebsite(event.target.value)} ref = {websiteRef} style = {textBoxOutline}/>
              <label style = {labelText}>Social Media Account</label>
              <input type = "text" placeholder = "@art101 on insta"  value={socialMediaAccount} onChange = {(event) => setSocialMediaAccount(event.target.value)} onClick = {() => setErrorSocialMediaAccount(false)}  ref = {socialMediaRef} style = {textBoxOutlineMedia}/>
              <label style = {labelText}>Tell us why you would like to be an Unveil artist <span style = {optional}>(optional)</span></label>
              <textarea type = "text" placeholder = "Start typing here..."  value={description} onChange = {(event) => setDescription(event.target.value)} ref = {descriptionRef} style = {textBoxOutlineLong}> </textarea>
            </div>
            <CustomButton type={'dark'} text={"Submit"} callbackfunction = {() => sendApplication()} height = {'5vh'} width = {'70%'}  mTop = {'2.5%'} wide = {true} loading = {isLoading}> </CustomButton>

          </div>
        </div> 
      </div>
  );
}





