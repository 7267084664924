import React from 'react'
import { getAuthToken } from '../database/AuthFunctions';
import { useNavigate } from 'react-router';
import { useEffect, useRef, useState, useMemo } from 'react';
import {getPieces, getUser } from '../database/UserFunctions';
import CustomButton from './CustomButton';
import greenCheck from '../assets/greenCheck.png'
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray, darkCheck, lightGreen} from '../styles/Colors'
import { editProfileFlexBox, headerTextPP, editProfileBlockOne, profilePictureContainer, editProfileBlockTwo, profilePicture, photoContainer, inputRowFlexer, rowFlexElement, buttonSeperator } from '../styles/EditProfileStyles';
import { headerTextInputs, topTextBoxFlexer, label, labelSmall, genreGrid, genreScroll, genreButtonBox, genreButtonSelectedBox, helperSub, errorGenreText, greenCheckMark, successText} from '../styles/EditProfileStyles';
export default function EditProfile({uid}) {


    //^ CONSTANTS (DATA): 
    const navigate = useNavigate()
    const [profilePic, setProfilePic] = useState('')
    const [username, setUserName] = useState('')
    const [name, setName] = useState('')
    const [city, setCity] = useState('')
    const [genres, setGenres] = useState([])
    const [artBio, setArtBio] = useState('')

    //^ CONSTANTS (DATA HELPERS)
    const [newProfilePic, setNewProfilePic] = useState('')
    const [chosenGenreIndex, setChosenGenreIndex] = useState([])
    const genreList = ['contemporary', 'expressionism', 'cubism', 'conceptualism', 'modernism', 'surrealism', 'abstract', 'realism', 'romanticism', 'illustration', 'pop-art', 'impression']
    const genreRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()])
    const [isLoading, setIsLoading] = useState(false)

    //^ CONSTANTS (ERRORS & Navigation)
    const usernameRef = useRef()
    const nameRef = useRef()
    const cityRef = useRef()
    const artBioRef = useRef()
    const noGenreErrorRef = useRef()
   

    const [errorUserName, setErrorUserName] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const [errorCity, setErrorCity] = useState(false)
    const [errorGenres, setErrorGenres] = useState(false)
    const [errorArtBio, setErrorArtBio] = useState(false)
    const [showNotification, setShowNotification] = useState(false);


    //&Function to fetch relevant user data for edit Profile 
    async function fetchUserData() {
        let userModel = await getUser(uid)
        setProfilePic(userModel.pfp)
        setUserName(userModel.username)
        setName(userModel.name)
        setCity(userModel.city)
        setArtBio(userModel.bio)
        if (userModel.genres != null) {
            for (let i = 0; i < userModel.genres.length; i++) {
                setGenres(genres => [userModel.genres[i].name, ...genres])
            }
            
        }    
    } 
 
    //& Send edit profile API Request and return if profile is updated or errors
    //todo: Fill this out with Rohan's API CAll 
    async function editProfileAPICall() {
        setIsLoading(true)
        var headers = new Headers();
        if (username == '') {
            setErrorUserName(true)
        }
        if (name == '') {
            setErrorName(true)
        }
        if (city == '') {
            setErrorCity(true)
        }
        if (artBio == '') {
            setErrorArtBio(true)
        }

        if (artBio == '' || city == '' || name == '' || username == '')  {
            console.log("error, request to change profile contained empty string")
            setIsLoading(false)
            return
        }
        else { 
            headers.append("Content-Type", "application/json")
            headers.append("Authorization", await getAuthToken());
            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    uid: uid,
                    username: username,
                    name: name,
                    bio: artBio,
                    city: city,
                    pfp: profilePic,
                    genres: genres.map(genre => ({ name: genre }))
          
                }),
                redirect: 'follow'
              }
              
              let res = await fetch("https://us-central1-artisynapp.cloudfunctions.net/users/editUser", requestOptions)
          
            if(res.status == 200) {
                setIsLoading(false)
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
                console.log("Success")
                return
            } else {
                setIsLoading(false)
                console.log('encountered dead state, listing request did not go through')
                return
            }
        }
       
    }

    //& Hovering background color effect change for genres
    const hoverGenre = (genre, index, action) => {

        if (action == 'enter' && (!genres.includes(genre))) {
            console.log('got here in hover')
            genreRefs.current[index].current.style.backgroundColor = 'rgba(0, 0, 0, .1)'
        }
        if (action == 'exit' && (!genres.includes(genre))) {
            genreRefs.current[index].current.style.backgroundColor = white
        }
    }

    //& Leverage useRefs and indexing to change styles of selected genre (soon to be genres)
    const selectGenre = (newGenre, indexToRemove) => { 
        
        // //! Remove error after any interactivity ..
        if (errorGenres)  noGenreErrorRef.current.style.display = 'none'
        
        //! START FUNC: if new genre is already in array and we clicked it so remove it
        if (genres.includes(newGenre)) setGenres((genres => genres.filter((genre, index) => genre != newGenre)))
        else { //* We want to add a genre.. 
            
            //? Are we at 3 genres and need to remove one before adding 
            if (genres.length == 3) {
                let tempGenresArray = [...genres]
                tempGenresArray.pop()
                setGenres(tempGenresArray)
            }
            setGenres(genres => [newGenre, ...genres])
        }
    }

    //& Preview new profile picture
    const previewProfile = (event) => { 
        setNewProfilePic(event.target.files[0])
    }

    //& Populate all fields with initial user data, set to be edited
    useEffect(() => {
        fetchUserData()

    }, []);
   
    //^TEXTBOX STYLES AND CATCH ERRORS
    const editSuccessNoti = useMemo(() => {
        return { 
            // ...other styles
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
            minHeight: '100%',
            margin: 0,
            backgroundColor: lightGreen,
            borderWidth: '3px 3px 3px 3px',
            borderLeftColor: darkCheck,
            borderTopColor: lightGreen,
            borderBottomColor: lightGreen,
            borderRightColor: lightGreen,
            borderStyle: 'solid',
            minWidth: '60%',
            visibility: showNotification ? 'visible' : 'hidden',
            opacity: showNotification ? 1 : 0,
            transformOrigin: 'left',
            transform: showNotification ? 'scaleX(1)' : 'scaleX(0)',
            transition: 'opacity 1s, transform 1s',
        }
    }, [showNotification])

    const textBoxOutlineShortUN = useMemo(() => {
        return { 
            width: '100%',
            height: '80%',
            outline: 'none',
            borderWidth: errorUserName ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
            borderColor: errorUserName ? red : lightGray,
            borderStyle: 'solid',
            backgroundColor: 'rgba(120, 153, 137, .1)',
            marginTop: 10,
            fontFamily:'ApercuProRegular',
            fontSize: '1.8vh',
            textIndent: 10,
            color: black
        }
    }, [errorUserName]) 

    const textBoxOutlineShortN = useMemo(() => {
        return { 
            width: '100%',
            height: '80%',
            outline: 'none',
            borderWidth: errorUserName ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
            borderColor: errorUserName ? red : lightGray,
            borderStyle: 'solid',
            backgroundColor: 'rgba(120, 153, 137, .1)',
            borderColor: lightGray,
            marginTop: 10,
            fontFamily:'ApercuProRegular',
            fontSize: '1.8vh',
            textIndent: 10,
            color: black
        }
    }, [errorName]) 
       
        
    const textBoxOutline = useMemo(() => { 
        return { 
            width: '90%',
            marginLeft: '5%',
            height: '8%',
            minHeight: '8%',
            outline: 'none',
            backgroundColor: 'rgba(120, 153, 137, .1)',
            borderWidth: errorUserName ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
            borderColor: errorUserName ? red : lightGray,
            borderStyle: 'solid',
            marginTop: '1%',
            fontFamily:'ApercuProRegular',
            fontSize: '1.8vh',
            textIndent: 10,
            marginBottom: 0,
            color: black,
        } 
    }, [errorCity])
    
    const textBoxOutlineDescrip = useMemo(() => {
        return { 
            width: '90%',
            maxWidth: '90%',
            minWidth: '90%',
            marginLeft: '5%',
            height: '20%',
            outline: 'none',
            borderWidth: errorUserName ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
            borderColor: errorUserName ? red : lightGray,
            borderStyle: 'solid',
            backgroundColor: 'rgba(120, 153, 137, .1)',
            borderColor: lightGray,
            marginTop: '1%',
            fontFamily:'ApercuProRegular',
            fontSize: '1.8vh',
            textIndent: 10,
            color: black,
            paddingTop: 15,
            lineHeight: '110%',
            paddingLeft: 10,
            paddingRight: 5
        }
    }, [errorArtBio])

    return (

    <div style = {editProfileFlexBox}>

        <div style = {editProfileBlockOne}> 
            <h1 style = {headerTextPP}>Profile Photo</h1>
            <div style = {profilePictureContainer}> 
                <div style = {photoContainer}> 
                    <img style= {profilePicture} src = {newProfilePic ?  URL.createObjectURL(newProfilePic) : profilePic} alt="Profile Picture" />
                </div>
                <input id = "profileInputTwo" type = "file"  onChange={previewProfile} hidden accept='image/*' />    
                <label htmlFor="profileInputTwo" style = {{backgroundColor: accentColor, color: white, width: '60%', height: '8%', marginTop: '5%', fontFamily: 'ApercuProMedium', justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: 18}}>Change Photo
                </label>   
            </div>
        </div>

        <div style = {editProfileBlockTwo}>
            <h1 style = {headerTextInputs}>Profile Details</h1>

            <div style = {inputRowFlexer}> 

                <div style = {rowFlexElement}> 
                  <label style = {labelSmall}>Username</label>
                  <input type = "text" value={username} onChange = {(event) => setUserName(event.target.value)} ref = {usernameRef} style = {textBoxOutlineShortUN} onClick={() => setErrorUserName(false)}/>
                </div>
                <div style = {rowFlexElement}>
                  <label style = {labelSmall}>Name</label>
                  <input type = "text" value={name} onChange = {(event) => setName(event.target.value)} ref = {nameRef} style = {textBoxOutlineShortN} onClick={() => setErrorName(false)}/>
                </div>
            </div>

            <label style = {label}>City</label>
            <input type = "text" value={city} onChange = {(event) => setCity(event.target.value)} ref = {cityRef} style = {textBoxOutline} onClick={() => setErrorCity(false)}/>

            <div style = {genreGrid}>

                <label style = {labelSmall}>Genres</label>
                {genres.length > 0  ?
                    <p style = {helperSub}>{genres.join(', ').toString()}</p> 
                    :
                    <p style = {helperSub}>3 genres maximum</p>

                }
                <div style = {genreScroll}>
                    {genreList.map((genre, index) => (
                        
                    <div ref = {genreRefs.current[index]} style = {genres.includes(genre) ? genreButtonSelectedBox : genreButtonBox} onClick = {()=> selectGenre(genre, index)} onMouseEnter = {() => hoverGenre(genre, index, 'enter')} onMouseLeave = {() => hoverGenre(genre, index, 'exit')}>{genre}</div> 
                    ))}
                </div>
            </div>

            <label style = {label}>Artist Statement</label>
            <textarea type = "text"  value={artBio} onChange = {(event) => setArtBio(event.target.value)} ref = {artBioRef} style = {textBoxOutlineDescrip} onClick={() =>setErrorArtBio(false)} > </textarea>
            <div style = {buttonSeperator}> 
                <CustomButton type={'dark'} text={"Submit"} callbackfunction = {() => editProfileAPICall()} height = {'100%'} width = {'30%'} loading={isLoading} wide = {false}> </CustomButton>
                <div style = {editSuccessNoti}>
                    <img src = {greenCheck} style = {greenCheckMark} />
                    <p style = {successText}>Changes successfully made to your profile.</p>
                </div>
            </div>
        </div>

    </div>

  )
}


