import React from 'react'
import {container, montrealSubHeader} from '../styles/Globals'
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
export default function NotificationSettings() {

    return (
        <div style = {container}>
            <h1 style = {montrealSubHeader}>Coming Soon!</h1>

        </div>
      )
}
   
