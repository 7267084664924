import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray, offGray} from '../styles/Colors'
import backgroundLandingImage from '../assets/backgroundLandingImage.png'
import artistFlicTwo from '../assets/artistFlic2.jpeg'

export const greenBackgroundImageMobile = {
    background: blurredWhite,
    width: '100vw',
    marginTop: '0', 
    height: '45vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'visible',
  
  }

  export const mobileMockupMobile = { 
    width: '85%',
    height: '110%',
  }

  export const subBlockFlexMobile = { 
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '100%',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    minHeight: '20vh',
    alignItems: 'flex-start',
    marginTop: '-40%', 
    padding: 0,
    justifyContent: 'space-between',
  }
  export const mobileHeaderText = { 
    fontFamily: 'PPNeueMontrealMedium', 
    fontSize: 18, 
    marginLeft: 'auto',
    marginTop: '5%',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignText: 'center',
    textAlign: 'center'
}

export const UnveilMobile = {
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: 38,
    marginTop: '15%',
    display: 'flex',
    justifyContent: 'center'
  }
  export const accentUnveilMobile = {
    color: primary,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: 38
  }

export const subMissionHeaderMobile = { 
    fontFamily: 'PPNeueMontrealItalic',
    fontSize: 12, 
    color: black,
    marginTop: '3%',
    minWidth: '100%',
    textAlign: 'center',

} 


export const missionCardsMobileS = { 
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '38%'
    
}
export const missionCardsMobileL = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '6%'
    
}
export const missionCardsMobileR = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '6%'
    
}

export const joinUsBetaText = { 
    fontFamily: 'ApercuProRegular',
    fontSize: 15, 
    color: black,
    textAlign: 'center',
    lineHeight: '140%'
}
