import {background, primary, secondary, orange, altGray, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
//^ GLOBALS
export const imageAndTextInputFlexSeperator = { 
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    minWidth: '95%',
    height: '62.5%',
    minHeight: '62.5%',
    marginTop: '2.5%', 
    marginLeft: '2.5%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
}
export const textInputs  = { 
  display: 'flex',
  flexDirection: 'column',
  width: '55%',
  height: '100%',
  justifyContent: 'flex-start',

}
export const label = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2.2vh',
    color: black,
}
export const labelAsterik = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2.2vh',
    color: red
}
export const helperSub = { 
    fontFamily: 'ApercuProRegular',
    fontSize: '1.8vh',
    color: darkGray
  }

//^ TEXT INPUTS
export const inputRowFlexer = { 
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: '14%',
    marginTop: '2.5%',
    marginBottom: 0
}
export const rowFlexElement = { 
    display: 'flex', 
    flexDirection: 'column',
    width: '47%',
    height: '100%'
}
export const genreGrid = { 
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2.5%',
    height: '25%',
    minHeight: '25%',
    marginBottom: '2.5%',
    width: '100%',
    minWidth: '100%',
}

export const genreScroll = { 
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    width: '100%',
    minWidth: '100%',
    height: '50%',
    minHeight: '50%',
  
  
}
export const genreButtonBox = { 
    fontFamily: 'ApercuProMedium',
    fontSize: '1.8vh',
    color: accentColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
    minWidth: '25%',
    minHeight: '90%',
    height: '90%',
    marginRight: '2.5%',
    backgroundColor: white,
    borderColor: accentColor,
    borderWidth: '2px, 2px, 2px, 2px',
    borderStyle: 'solid',
    borderRadius: 28,
    cursor: 'pointer'
}

export const genreButtonSelectedBox = { 
    fontFamily: 'ApercuProMedium',
    fontSize: '1.8vh',
    color: white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
    minWidth: '25%',
    minHeight: '90%',
    height: '90%',
    marginRight: '2.5%',
    backgroundColor: primary,
    borderColor: primary,
    borderWidth: '2px, 2px, 2px, 2px',
    borderStyle: 'solid',
    borderRadius: 28,
    cursor: 'pointer'
}

export const errorGenreText = { 
    display: 'none',
    fontFamily: 'ApercuProRegular',
    fontSize: '1.6vh',
    color: 'red',
    margin: 0,
    marginBottom: 0,
    marginTop: '-.5%',
   
}
export const noImageErrorText = { 
    display: 'none',
    fontFamily: 'ApercuProRegular',
    fontSize: '1.6vh',
    margin: 0,
    marginTop: '2.5%',
    marginBottom: 0,
    color: 'red'
  }
export const imageRemoveAction = { 
    fontFamily: 'ApercuProMedium',
    textDecoration: 'underline',
    color: red,
    fontSize: '1.6vh',
    cursor: 'pointer',
    textAlign: 'center',
    marginBottom: 0,
    display: 'none',
    whiteSpace: 'nowrap'
  }

//^IMAGE SELECTOR STYLES
export const imageInput = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: '5%',
    width: '40%',
    height: '100%'
}
export const emptyImageContainerLarge = { 
    backgroundColor: 'rgba(120, 153, 137, .2)',
    width: '100%',
    minWidth: '100%',
    height: '72%',
    minHeight: '72%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0
}
export const largeImageContainer = {
    width: '100%',
    minWidth: '100%',
    height: '72%',
    minHeight: '72%',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
}
export const imgPreviewLarge = { 
    margin: 0,
    padding: 0,
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '100%',
    objectFit: 'cover' 
}

export const artPlaceholderLarge = { 
    width: '25%',
    height: '25%'
}

export const imgPreviewFlex = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidht: '100%',
    marginTop: '2.5%',
    height: '12%',
    minHeight: '12%',
    alignItems: 'center',
    justifyContent: 'flex-start'
}
export const uploadButton = {
    backgroundColor: accentColor,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
  
export const imageContainer = { 
    width: '18%',
    minWidth: '18%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    marginLeft: '2.5%',
    flexDirection: 'column',
    alignItems: 'center'
}
export const emptyImageContainer = {
    width: '18%',
    minWidth: '18%',
    height: '100%',
    minHeight: '100%',
    marginLeft: '2.5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(120, 153, 137, .2)'
}
export const imgPreviewSmall = { 
    width: '100%',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    marginTop: 0,
    marginBottom: 0
}

export const artPlaceholderSmall = { 
    width: '30%',
    height: '30%'
}
export const whiteUploadIcon = { 
    width: '30%',
    height: '30%'
}