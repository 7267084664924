import Lottie from 'lottie-react';
import animationData from '../assets/bigLoader.json'
import { useEffect, useState } from 'react';
import { getPieces, getUser, getListingApplications } from '../database/UserFunctions';
import { useNavigate, Link, useLocation} from "react-router-dom"
import StickyNav from '../components/StickyNav';
import CustomButton from '../components/CustomButton';
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
import {ApplicationStatus, ListingRequestStatus, CampaignStatus, OptPeriod, FinalCampaignStatus, AcquisitonOffer, AcquisitionOfferStatus} from '../components/Notifications'
import { containerNavPresent, smallWhiteContainerNavPresent, headerTextNavPresent } from '../styles/Globals';
import { flexContainer, subHeaderText, notificationsFlex, notificationsScrollView, notisLoadingDiv, notificationsLoadedDiv, graySubTextEmptyData, notificationsPresentAndScrolling, individualNotification, listingsAndApplicationsFlex, listingsFlex, applicationsFlex, scrollView, listingsAndAppsLoaded, listingsAndAppsPresentAndScrolling, pieceCardFinal, pieceInner, cardImageContainer} from '../styles/HomeStyles';
import { pieceCard, priceDiv, pieceImage, pieceHeader, pieceDescription, noListingsDiv } from '../styles/HomeStyles';
export default function Home() {

  //^ CONSTANTS, DATA:
  const [listings, setListings] = useState([])
  const [listingApplications, setListingApplications] = useState([])
  const [notifications, setNotifications] = useState([])

  //^CONSTANTS, DATA HELPERS
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoadingNotis, setIsLoadingNotis] = useState(false)
  const [isLoadingApps, setIsLoadingApps] = useState(false)
  const [isLoadingListings, setIsLoadingListings] = useState(false)

  //& Lottie loading constant 
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData
   
  }
  //& Get Piece Function (for notifications)
  const getListing = (pieceID) => {
    if (listings.length > 0) {
      for (let i = 0; i < listings.length; i++) { 
        if (listings[i].id == pieceID) return listings[i]
        else continue
      }
      console.log('No listing found with ID: ' + pieceID)
      return -1
    }
    else {
      console.log("Listing array is empty, cannot find listing with ID: " + pieceID)
      return -1
    }
  }

  //& Get Application (for notifications)
  const getListingApplication = (appID) => {

    if (listingApplications.length > 0) {
      for (let i = 0; i < listingApplications.length; i++) { 
        if (listingApplications[i].id == appID) return listingApplications[i]
        else continue
      }
      console.log('No listing Application found with ID: ' + appID)
      return -1
    }
    else {
      console.log("Listings Applications array is empty, cannot find listing application with ID: " + appID)
      return -1
    }
  }

  //& Fetch all needed data for home screen
  useEffect(() => {
    //& Function to fetch all data relevant to the home screen
    async function fetchAllUserData() {
      
      //! Set all lists to loading state
      setIsLoadingApps(true)
      setIsLoadingNotis(true)
      setIsLoadingListings(true)

      let userModel = await getUser(location.state.uid)
      //! Populate all Listings (inefficient must fix later)
      const pieceUIDs = []
      if (userModel.listedPieces != null) { 
        for (let i = 0; i < userModel.listedPieces.length; i++) pieceUIDs[i] = userModel.listedPieces[i]
      }
      let pieceModels = await getPieces(pieceUIDs)
      setListings(pieceModels.pieces)
      setIsLoadingListings(false)

      //! Populate all Listing Applications
      const listingUIDs = []
      if (userModel.listingApplications != null) {
        for (let i = 0; i < userModel.listingApplications.length; i++) listingUIDs[i] = userModel.listingApplications[i]
      } 
      let listingApplicationsTemp = await getListingApplications(listingUIDs)
      setListingApplications(listingApplicationsTemp.pieces)
      setIsLoadingApps(false)

      //! Populate all notifications
      if(userModel.events != null) setNotifications(userModel.events.reverse())
      setIsLoadingNotis(false)
    } 
    fetchAllUserData()

    
  }, []);

 
  return(
    <div style = {containerNavPresent}>
      <StickyNav tab = 'home' userUID = {location.state.uid} pfpSrc={location.state.pfp} disabled = {false}></StickyNav>


      <div style = {smallWhiteContainerNavPresent}> 

        <h1 style = {headerTextNavPresent}>Artist Dashboard</h1>
        <div style = {flexContainer}> 
          <div style = {notificationsFlex}>
            <h2 style = {subHeaderText}>Notifications</h2>

            <div style = {notificationsScrollView}> 
            {isLoadingNotis ? 
                <div style = {notisLoadingDiv}> <Lottie animationData={animationData} loop = {true} autoPlay={true} height={'100%'} width={'100%'}/> </div>
              :
              <div style = {notificationsLoadedDiv}> 
                {notifications.length <= 0 ? 
                  <p style = {graySubTextEmptyData}>You have no new notifications</p>
                :
                <div style = {notificationsPresentAndScrolling} >
                  {notifications.map((notification, index) => (
                  <div style = {individualNotification}>                       
                    {(() => {
                      switch (notification.type) {
                        case 'artistApplicationAccepted':
                          return <ApplicationStatus status = {notification.type}> </ApplicationStatus> 
                        case 'artistApplicationRejected':
                          return <ApplicationStatus status = {notification.type}> </ApplicationStatus> 
                        case 'listingRequestAccepted':
                          return <ListingRequestStatus status = {notification.type} listingApplication={getListingApplication(notification.pieceID)}> </ListingRequestStatus>
                        case'listingRequestRejected': 
                          return <ListingRequestStatus status = {notification.type} listingApplication={getListingApplication(notification.pieceID)}> </ListingRequestStatus>
                        case 'waitlistBegun':
                          return <CampaignStatus status = {notification.type} listing={getListing(notification.pieceID)}></CampaignStatus>
                        case 'campaignFailure':
                          return <CampaignStatus status = {notification.type} listing={getListing(notification.pieceID)}></CampaignStatus>
                        case 'optOutBegun':
                          return <OptPeriod  status = {notification.type} listing={getListing(notification.pieceID)}></OptPeriod>
                        case 'optInBegun':
                          return <OptPeriod  status = {notification.type} listing={getListing(notification.pieceID)}></OptPeriod>
                        case 'campaignSuccess':
                          return <FinalCampaignStatus  status = {notification.type} listing={getListing(notification.pieceID)}></FinalCampaignStatus>
                        case 'campaignOptFailure': 
                          return <FinalCampaignStatus  status = {notification.type} listing={getListing(notification.pieceID)}></FinalCampaignStatus>
                        case 'acquisitionOffer':
                          return <AcquisitonOffer  status = {notification.type} listing={getListing(notification.pieceID)}></AcquisitonOffer>
                        case 'acquisitionOfferAccepted':
                          return <AcquisitionOfferStatus  status = {notification.type} listing={getListing(notification.pieceID)}></AcquisitionOfferStatus>
                        case 'acquisitionOfferRejected':
                          return <AcquisitionOfferStatus  status = {notification.type} listing={getListing(notification.pieceID)}></AcquisitionOfferStatus>
                        default:
                          console.log('hit default case with notification: ' + notification.type)
                          return null
                      }
                    })()}
                  </div>
                  ))}
                </div>
                }
              </div>
              }
            </div>
          </div>

          <div style = {listingsAndApplicationsFlex}>

            <div style = {listingsFlex}>
              <h2 style = {subHeaderText}>Current Listings</h2>
              <div style = {scrollView}> 

                {isLoadingListings ? 
                    <div style = {notisLoadingDiv}><Lottie animationData={animationData} loop = {true} autoPlay={true}  height={'100%'} width={'100%'}/> </div>
                  :
                  <div style = {listingsAndAppsLoaded}>
                    {listings.length <= 0 ? 
                      <p style = {graySubTextEmptyData}>You have no current listings</p>
                    :
                    <div style = {listingsAndAppsPresentAndScrolling} >
                      {listings?.map((listedPiece, index) => (
                                              
                          <div style = {index + 1 == listings.length ? pieceCardFinal : pieceCard} onClick = {() => navigate('/PieceSelected', {state: {uid: location.state.uid, pfp: location.state.pfp, piece: listedPiece}})}> 
                          
                            <div style = {pieceInner}>
                              <div style = {priceDiv}>${listedPiece.price} </div>
                              <div style = {cardImageContainer}> 
                                <img src = {listedPiece.images[0]} style = {{minWidth: '100%', width: '100%', height: '100%', minHeight: '100%'}}/>
                              </div>
                            </div>
                        
                            <h1 style = {pieceHeader}> {listedPiece.title}</h1>
                            <p style = {pieceDescription}>{listedPiece.description.substring(0, 80) + '...'}</p>
                          </div>
                      
                      ))}
                    </div>
                    }
                  </div>
                }
              </div>
            </div>

            <div style = {applicationsFlex}>
              <h2 style = {subHeaderText}>Listing Applications</h2>
              <div style = {scrollView}>

                {isLoadingApps ?  
                   <div style = {notisLoadingDiv}><Lottie animationData={animationData} loop = {true} autoPlay={true} height={'100%'} width={'100%'}/> </div>
                  :
                  <div style = {listingsAndAppsLoaded}>
                  {listingApplications?.length <= 0 ? 
                    <div style = {noListingsDiv}>
                      <p style = {graySubTextEmptyData}>You have no listing applications</p>
                      <CustomButton type={'dark'} text={"Create Listing"} callbackfunction = {() => navigate('/CreateListing', {state: {uid: location.state.uid}})} height = {'40%'} width = {'70%'}> </CustomButton>
                    </div>
                  :
                  <div style = {listingsAndAppsPresentAndScrolling} >
                    {listingApplications?.filter(e => e.status != 'applicationAccepted').map((listingApp, index) => (
                  
                      <div style = {index + 1 == listingApplications.filter(e => e.status != 'applicationAccepted').length ? pieceCardFinal : pieceCard}> 
                        <div style = {pieceInner}>
                          <div style = {priceDiv}>${listingApp.price} </div>
                          <div style = {cardImageContainer}> 
                            <img src = {listingApp.images[0]} style = {{minWidth: '100%', width: '100%', height: '100%', minHeight: '100%'}}/>
                          </div>
                        </div>
                        <h1 style = {pieceHeader}> {listingApp.title}</h1>
                        <p style = {pieceDescription}>{listingApp.description.substring(0, 80) + '...'}</p>
                      </div>
                    
                    ))}
                  </div>
                  
                  }
                </div> 
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
   
  )
}
















