import {background, primary, altGray, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'


export const inputContainer = { 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
    height: '70%',
    marginTop: '1%',
    justifyContent: 'flex-start',
    marginLeft: '5%',
}
export const inputsContainer = {
    display: 'flex',
    width: '45%',
    height: '90%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
}
export const mockUpContainer = {
    width: '50%',
    height: '90%',
    marginLeft: '5%',
}
export const profileInputContainer = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '40%',
}



export const mockupProfile = {
   width: '100%' ,
   height: '100%'
}
export const labelText = {
    color: darkGray,
    fontFamily: 'ApercuProRegular',
    padding: 0,
    fontSize: '2vh',
    marginTop: '5%',
    marginBottom: 0,
}


export const imageEmptyContainer = { 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'flex-start', 
    alignItems: 'center',

}

export const blankProfile = () => {
    let width = '100px';
    let height = '100px';
  
    if (typeof window !== 'undefined') {
      if (window.matchMedia('(min-width: 1280px)').matches) {
        width = '110px';
        height = '110px';
      }
  
      if (window.matchMedia('(min-width: 1366px)').matches) {
        width = '120px';
        height = '120px';
      }
  
      if (window.matchMedia('(min-width: 1440px)').matches) {
        width = '135px';
        height = '135px';
      }
  
      if (window.matchMedia('(min-width: 1920px)').matches) {
        width = '150px';
        height = '150px';
      }
    }
  
    return {
      borderRadius: '100%',
      width: width,
      height: height,
      borderWidth: '2px 2px 2px 2px',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderColor: lightGray,
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
};

export const uploadPhotoButton = { 
    color: primary, 
    width: '35%', 
    minWidth: '35%',
    height: '20%',
    minHeight: '20%', 
    marginLeft: '5%',
    fontFamily: 'ApercuProMedium', 
    borderWidth: '0px 0px 2px 0px',
    borderStyle: 'solid',
    borderColor: primary,
    justifyContent: 'flex-start', 
    display: 'flex', 
    alignItems: 'center', 
    fontSize: '2.6vh',
    whiteSpace: 'nowrap'
}
export const bigUserIcon = { 
    width: '45%',
    height: '45%'
    
}


  

export const profilePicture = { 
    width: '100%',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    borderRadius: '100%'
}