import { getAuthToken } from "./AuthFunctions";

export async function getUser(uid) {
  
  var headers = new Headers();
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", await getAuthToken());

  var requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      uid: uid
    }),
    redirect: 'follow'
  }

  let res = await fetch("https://us-central1-artisynapp.cloudfunctions.net/users/getUser", requestOptions)

  if(res.status == 200) {
    return await res.json()
  } else {
    return {status: "failed"}
  }
}

export async function getPieces(pieceUIDS) {
  var headers = new Headers();
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", await getAuthToken());
  
  var requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      pieceIDs: pieceUIDS
    }),
    redirect: 'follow'
  }

  let res = await fetch("https://us-central1-artisynapp.cloudfunctions.net/pieces/getPieces", requestOptions)

  if(res.status == 200) {
    return await res.json()
  } else {
    console.log('getting pieces failed')
    return {status: "failed"}
  }
}

export async function getListingApplications(listingUIDS) {
  var headers = new Headers();
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", await getAuthToken());
  
  var requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      pieceIDs: listingUIDS
    }),
    redirect: 'follow'
  }

  let res = await fetch("https://us-central1-artisynapp.cloudfunctions.net/pieces/getListingApplications", requestOptions)

  if(res.status == 200) {
    return await res.json()
  } else {
    console.log('getting pieces failed')
    return {status: "failed"}
  }
}