import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'



export const container = { 
  backgroundColor: 'rgba(120, 153, 137, .2)',
  margin: 0,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 0,
  marginBottom: 0
}

export const loginContainer = { 
    backgroundColor: white,
    width: '40vw',
    height: '65vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}

export const title = {
    fontFamily: 'PPNeueMontrealBold', 
    color: black, 
    fontSize: '4.5vh',
    marginTop: '7.5%'
}
export const subText = {
    fontFamily: 'ApercuProRegular', 
    fontSize: '2.2vh' ,
    color: black, 
    width: '30vw',
    textAlign: 'center',
    lineHeight: '140%', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    marginTop: '-2%'
}
export const inputFlex = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '70%',
    marginTop: '2.5%',
    height: '30%',

}
export const labelTextOne = {
    color: darkGray,
    fontFamily: 'ApercuProRegular',
    padding: 0,
    fontSize: '1.8vh',
    marginBottom: 0
}

  export const labelTextTwo = {
    color: darkGray,
    fontFamily: 'ApercuProRegular',
    padding: 0,
    marginTop: '5%',
    fontSize: '1.8vh',
    marginBottom: 0,
  }
  export const labelTextThree = { 
    color: darkGray,
    fontFamily: 'ApercuProRegular',
    fontSize: '1.6vh', 
    marginTop: '3%'
  
}

export const passwordFlexer = { 
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '25%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '1%'
}
export const passwordVisibilityChanger = { 
    height: '50%', 
    marginLeft: '-10%',
    zIndex: 2,
}

export const loginHelpDiv = { 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    display: 'flex', 
    alignItems: 'center', 
    width: '70%', 
    marginLeft: 'auto',
    marginRight: 'auto',


}
export const rememberMeDiv = { 
    flexDirection: 'row', 
    justifyContent: 'flex-start',
    display: 'flex', 
    alignItems: 'center', 
    width: '35%',
    height: '100%',
}


//^TEXT STYLES 
export const rememberMeText = {
    fontFamily: 'ApercuProRegular',
    fontSize: '1.8vh',
    color: black,
    width: '100%',
    marginLeft: '5%',
    whiteSpace: 'nowrap'
}
export const forgotPasswordText = { 
    fontFamily: 'ApercuProBold',
    fontSize: '1.8vh',
    color: primary,
    cursor: 'pointer',
    whiteSpace: 'nowrap'
}
export const noAccountText = { 
    fontFamily: 'ApercuProBold',
    fontSize: '2vh',
    color: primary,
    textAlign: 'center',
    display: 'inline',
    marginTop: '3%',
    cursor: 'pointer'
}
export const noAccountTextUnderline = {
    fontFamily: 'ApercuProBold',
    fontSize: '2vh',
    color: primary,
    textDecoration: 'underline',
    display: 'inline',
    cursor: 'pointer'

}
export const disclaimer = { 
    fontFamily: 'ApercuProMedium',
    fontSize: '2.2vh',
    color: black,
    textAlign: 'center',
    width:  '60%',
    marginTop: '5%'

}
