import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from './Colors'


export const artistImageFlexer = { 
    width: '50%',
    height: '120%',
    marginRight: 0,
    marginLeft: '20%',
    display: 'flex',
    flexDirection: 'column',
}


export const artistImageContainer = { 
    width: '100%',
    height: '90%',
    borderColor: 'rgba(120, 153, 137, .1)',
    borderWidth: '2px 2px 2px 2px',
    borderStyle: 'solid',
    overflow: 'hidden',
    boxShadow: '2, 0, 4, rgba(0, 0, 0, .25)'
}
export const artistImage =  { 
    width: '100%',
    height: '100%'
}
export const artistDescriptor = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
}
export const artistTag = { 
    color: darkGray,
    fontFamily: 'ApercuProBold',
    fontSize: '16px',
    display: 'inline',
    whitespace: 'nowrap',
    width: '100%'
}
export const artistLocation = { 
    color: darkGray,
    fontFamily: 'ApercuProRegular',
    fontSize: '16px'
}
export const unveilTag = { 
    color: darkGray,
    fontFamily: 'ApercuProItalic',
    fontSize: '16px',
    marginTop: '-1.5%'
}

// export const artistImageOneBox = {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'flex-start',
//     alignItems: 'center',
//     width: '80%',
//     height: '60%',
//     zIndex: 1,
//     border: '2px solid red'
// }
// export const artistImageTwoBox = {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'flex-start',
//     alignItems: 'center',
//     marginTop: '-80%',
//     width: '30vw',
//     height: '40vh',
//     marginLeft: '30%',
//     zIndex: 2,
    
// }
// export const artistImageThreeBox = {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'flex-start',
//     alignItems: 'center',
//     width: '30vw',
//     height: '40vh',
//     marginTop: '-7.5%',
//     marginLeft: '60%',
//     overflow: 'visible',
// }

