import {BrowserRouter as Router, Routes, useRoutes, Route, Navigate} from "react-router-dom";
//^ Imported Screens
import ApplicationAccepted from "./screens/ApplicationAccepted"
import ApplicationPending from "./screens/ApplicationPending"
import ApplicationRejected from "./screens/ApplicationRejected" 
import Apply from "./screens/Apply"
import Home from "./screens/Home"

import CreateListing from "./screens/CreateListing" 
import WelcomeBackLogin from "./screens/WelcomeBackLogin.js"
import CompleteProfile from "./screens/CompleteProfile"
import Metrics from "./screens/Metrics"
import Settings from "./screens/Settings"
import ForgotPassword from "./screens/ForgotPassword";
import ResetSuccess from "./screens/ResetSuccess";
import PieceSelected from "./screens/PieceSelected";
import Landing from "./screens/Landing.js";
import ArtistSignup from "./screens/ArtistSignup";
import ListingAppComplete from "./screens/ListingAppComplete";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TOS from "./screens/TOS";
import Redirect from "./screens/Redirect";

function App() {

  function ProtectedRoute({ element, path }) {
    const isAuthenticated = sessionStorage.getItem('authenticated') === 'true';
    return isAuthenticated ? element : <Navigate to="/" replace />;
  }
  return (

    <div style = {{width: '100%', margin: '0%', padding: '0%'}}>
      <Router> 
        <Routes>
            <Route exact path = "/Artist" element = {<Landing/>}/>
            <Route exact path = "/PrivacyPolicy" element = {<PrivacyPolicy/>} />
            <Route exact path = "/TermsAndConditions" element = {<TOS/>} />
            <Route exact path = "/Redirect" element = {<Redirect/>} />
            <Route path="/WelcomeBackLogin" element={<WelcomeBackLogin />} />
            <Route path="/ArtistSignup" element={<ArtistSignup />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            
            <Route path="/Home" element={<ProtectedRoute element={<Home />} />} />
            <Route path="/ApplicationAccepted" element={<ProtectedRoute element={<ApplicationAccepted />} />} />
            <Route path="/ApplicationPending" element={<ProtectedRoute element={<ApplicationPending />} />} />
            <Route path="/ApplicationRejected" element={<ProtectedRoute element={<ApplicationRejected />} />} />
            <Route path="/CreateListing" element={<ProtectedRoute element={<CreateListing />} />} />
            <Route path="/ListingAppComplete" element={<ProtectedRoute element={<ListingAppComplete />} />} />
            <Route path="/Apply" element={<ProtectedRoute element={<Apply />} />} />
            <Route path="/CompleteProfile" element={<ProtectedRoute element={<CompleteProfile />} />} />
            <Route path="/Settings" element={<ProtectedRoute element={<Settings />} />} />
            <Route path="/Metrics" element={<ProtectedRoute element={<Metrics />} />} />
         
            <Route path="/ResetSuccess" element={<ProtectedRoute element={<ResetSuccess />} />} />
            <Route path="/PieceSelected" element={<ProtectedRoute element={<PieceSelected />} />} />
        </Routes>
      </Router>
  </div>
  );
}

export default App;