export const background = "#F9F9F8"
export const primaryDark = "#0D0B0F";
export const secondaryDark = "#464851";

export const gray = "#80888D";
// export const lightGray = "#EDF2FF";
export const offGray = "#F1F5FF"
export const teal = "#0C474B"
export const offTeal = "#D5DDE3"
export const otherBlack = "333135"

//! COLORS
export const primary = "#789989"
export const secondary = "#638172"
export const accentColor = "#1C3229"
export const white = "#ffffff"
export const blurredWhite = 'rgba(255, 255, 255, 0.2)'
export const black = "#0B0A0A"
export const green = "#63AD89"
export const red = "#D74646"
export const orange = "#EA9648"
export const darkGray = "#697077"
export const lightGray = "#C1C7CD"
export const altGray = "#4F5351"
export const textBoxColor = "#E4EBE7"
export const disabledAccent = '#BCC0BF'
export const lightGreen = "#C6ECD9"
export const darkCheck = "#63AD89"