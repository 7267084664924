import React from 'react'
import Lottie from 'lottie-react';
import animationData from '../assets/loader.json'
import '../styles/buttons.css'
import upload from '../assets/upload.png'
import { primary, secondary, accentColor, white, black, green, red, orange } from '../styles/Colors'

const CustomButton = ({type, text, callbackfunction, height, width, iconLeft, iconRight, mGin, mTop, mBottom, mLeft, mRight, loading, wide}) => { 

    const styledButton = { 
    
        width: '100%',
        height: '100%',
        backgroundColor: type=='outline' ? white : accentColor,
        color: type=='outline' ? black : white,
        borderWidth: '2px 2px 2px 2px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        borderColor: type=='outline' ? black : '#28303F',
        borderStyle: 'solid',
        fontFamily: 'ApercuProMedium',
        fontSize: '2.5vh',
        letterSpacing: .5,
        cursor: 'pointer',
    }
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData
       
    };

  

    return (
        <div style ={{justifyContent: 'center', alignItems: 'center', display: 'inline-flex', width: width, height: height, minHeight: height, minWidth: width,  marginTop: mTop, marginBottom: mBottom, marginLeft: mLeft, marginRight: mRight}}> 

            {loading==true ?
                
                <div style={styledButton} onClick={callbackfunction}> 
                    <div style={{width: wide== true ? '18%' : '24%', height: wide== true ? '18%' : '24%', display: 'flex', marginTop: '1%', justifyContent: 'center', alignItems: 'center'}}> 
                        <Lottie animationData={animationData}
                            loop={true}
                            autoplay={true}
                            width= {'100%'}
                            height= {'100%'}
                        />
                    </div>
                </div> 
                
             :
             <div className='customButton' style = {styledButton} onClick={callbackfunction}> {text} 
                </div> 
            }
        </div>
      
    )  
}

export default CustomButton
