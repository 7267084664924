import React from 'react'
import { useNavigate, Link, useLocation, } from "react-router-dom"
import { useState, Input, useRef,  useEffect, useMemo} from 'react';
import { getAuthToken, uploadFile } from '../database/AuthFunctions';
import CustomButton from '../components/CustomButton';
import '../styles/createlisting.css'
import artPlaceholder from '../assets/artPlaceholder.svg'
import whiteUpload from '../assets/whiteUpload.svg'
import StickyNav from '../components/StickyNav';
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, altGray, darkGray, textB} from '../styles/Colors'
import {containerNavPresent, smallWhiteContainerNavPresent, headerTextNavPresent, headerSubNavPresent } from '../styles/Globals';
import { imageAndTextInputFlexSeperator, textInputs, label, labelAsterik, helperSub, inputRowFlexer, rowFlexElement, genreGrid, genreButtonBox, errorGenreText, genreScroll, genreButtonSelectedBox, largeImageContainer, emptyImageContainerLarge, imageInput, imgPreviewLarge, artPlaceholderLarge } from '../styles/CreateListingStyles';
import { imgPreviewFlex, uploadButton, imageContainer, emptyImageContainer, imgPreviewSmall, artPlaceholderSmall, whiteUploadIcon, imageRemoveAction, noImageErrorText } from '../styles/CreateListingStyles';

//todo: Multiple genres and how that affects components in this screen 
//todo: expand error handling (red outlines and disabled until each our fixed ? )


export default function CreateListing() {

  //^CONSTANTS (DATA)
  const [name, setName] = useState('')
  const [medium, setMedium] = useState('')
  const [price, setPrice] = useState('')
  const [genres, setGenres] = useState([]) 
  const [descrip, setDescrip] = useState('')
  const [images, setImages] = useState([])

  //^ CONSTANTS (DATA HELPERS)
  const [chosenGenreIndex, setChosenGenreIndex] = useState(-1)
  const genreList = ['contemporary', 'expressionism', 'cubism', 'conceptualism', 'modernism', 'surrealism', 'abstract', 'realism', 'romanticism', 'illustration', 'pop-art', 'impression']
  const genreRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()])
  const removePictureRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()])
  const imgPreviewSelectedRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()])
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const [imagePreviewIndex, setImagePreviewIndex] = useState(0)
 
  //^ERROR CONSTANTS
  const [errorName, setErrorName] = useState(false)
  const [errorMedium, setErrorMedium] = useState(false)
  const [errorPrice, setErrorPrice] = useState(false)
  const [errorGenres, setErrorGenres] = useState(false)
  const [errorDescrip, setErrorDescrip] = useState(false)
  const [errorImages, setErrorImages] = useState(false)
  const nameRef = useRef()
  const mediumRef = useRef()
  const priceRef = useRef()
  const descripRef = useRef() 
  const uploadButtonRef = useRef()
  const noImageErrorRef = useRef()
  const noGenreErrorRef = useRef()
 

  //& Let user preview the images of their art 
  const previewFiles = (event) => {

    //! Remove error after any interactivity ..
    if (errorImages) {
      noImageErrorRef.current.style.display = 'none'
      uploadButtonRef.current.style.border = 'none'
    } 
    //! If the user has inputed 4 or less images.. 
    if (event.target.files.length < 5) {
      for (let i = 0; i < event.target.files.length; i++ ) {
        if (!images.includes(event.target.files[i])) setImages(images => [...images, event.target.files[i]]) //* do not add duplicate image
      }
    } 
    //! Otherwise they inputted too many images 
    else { 
      for (let i = 0; i < event.target.files.length; i++ ) {
        
        if (i > 3 && images.length >= 4) { //* reached too many images (requires both conditions because some re-uploads may be duplicates)
          break
        }
        if (!images.includes(event.target.files[i])) setImages(images => [...images, event.target.files[i]]) //* do not add duplicate image
      }
    }
  };

  //& Leverage useRefs and indexing to change styles of selected genres
  const selectGenre = (newGenre, indexToRemove) => { 
        
    // //! Remove error after any interactivity ..
    if (errorGenres)  noGenreErrorRef.current.style.display = 'none'
    
    //! START FUNC: if new genre is already in array and we clicked it so remove it
    if (genres.includes(newGenre)) setGenres((genres => genres.filter((genre, index) => genre != newGenre)))
    else { //* We want to add a genre.. 
        
        //? Are we at 3 genres and need to remove one before adding 
        if (genres.length == 3) {
            let tempGenresArray = [...genres]
            tempGenresArray.pop()
            setGenres(tempGenresArray)
        }
        setGenres(genres => [newGenre, ...genres])
    }
}

  //& Hovering background color effect change for genres
  const hoverGenre = (genre, index, action) => {

    if (action == 'enter' && (!genres.includes(genre))) {
      genreRefs.current[index].current.style.backgroundColor = 'rgba(0, 0, 0, .1)'
    }
    if (action == 'exit' && (!genres.includes(genre))) {
      genreRefs.current[index].current.style.backgroundColor = white
    }
  }

  //& Click X button and remove image from previewer
  const handleRemoveImage = (imageName) => { 
    setImages(images => images.filter(image => image.name !== imageName))

    //! Revert style of removed image box (worst case useEffect reverts it back
    imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.border = 'none'
    removePictureRefs.current[imagePreviewIndex].current.style.display = 'none'
    setImagePreviewIndex(0)
        
  }

  //& Handle changing of preview Image 
  const selectPreviewImage = (newImageToPreview, newPreviewIndex) => {

    if (imagePreviewIndex != newPreviewIndex) { //* different image than what we are already previewing
      
      //! Reset border of previous preview and remove "remove image button"
      imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.border = 'none'
      removePictureRefs.current[imagePreviewIndex].current.style.display = 'none'

      //! New border for new preview and new remove button
      imgPreviewSelectedRefs.current[newPreviewIndex].current.style.borderWidth = '3px 3px 3px 3px'
      imgPreviewSelectedRefs.current[newPreviewIndex].current.style.borderColor = primary
      imgPreviewSelectedRefs.current[newPreviewIndex].current.style.borderStyle = 'solid'
      removePictureRefs.current[newPreviewIndex].current.style.display = 'flex'

      //! Update tracker variables
      setImagePreviewIndex(newPreviewIndex)
    }
  }
  function removeNonNumericChars(str) {
    return str.replace(/\D/g, '');
  }
  
  //& Upload Listing application 
  async function sendPieceApplication() {

    
    setIsLoading(true)

    //! Passed all error checks.. now we can upload images to storage
    const imageURLs = []; 
    for (let i = 0; i < images.length; i++) { 
      const image = images[i];
      imageURLs[i] = await uploadFile(image, 'artImages');
      console.log("image URL " + i + " is " + imageURLs[i])
    }

    //! Make API Request
    var headers = new Headers();
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", await getAuthToken());
    
    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        uid: location.state.uid,
        description: descrip,
        genre: genres,
        price: removeNonNumericChars(price),
        images: imageURLs,
        medium: medium, 
        title: name 

      }),
      redirect: 'follow'
    }
    
    let res = await fetch("https://us-central1-artisynapp.cloudfunctions.net/artists/submitListingApplication", requestOptions)

    if(res.status == 200) {
      setIsLoading(false)
      console.log("Success")
      navigate("/ListingAppComplete", {state: {uid: location.state.uid, pfp: location.state.pfp}})
    } else {
      console.log("error, request to list piece failed")
      setIsLoading(false)
      console.log('genres length is ' + genres.length)
      //! Handle errors 
      if (name == '') setErrorName(true)
      if (medium == '') setErrorMedium(true)
      if (price == '') setErrorPrice(true)
      if (genres.length == 0) {
        setErrorGenres(true)
        noGenreErrorRef.current.style.display = 'flex'
      }
      if (descrip == '') setErrorDescrip(true)
      if (images.length == 0) { //? (assume that we would have caught more than 4 images uploaded with previewFiles)
        setErrorImages(true)
        noImageErrorRef.current.style.display = 'flex'
      }
    }
  }


  //& On change to images array revert preview to initial preview
  useEffect(() => {
    if (images.length > 0) {

      imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.border = 'none'
      removePictureRefs.current[imagePreviewIndex].current.style.display = 'none'
      imgPreviewSelectedRefs.current[0].current.style.borderWidth = '3px 3px 3px 3px'
      imgPreviewSelectedRefs.current[0].current.style.borderColor = primary
      imgPreviewSelectedRefs.current[0].current.style.borderStyle = 'solid'
      removePictureRefs.current[0].current.style.display = 'flex'
      setImagePreviewIndex(0)
    }
   
  }, [images])


  //^TEXTBOX INPUTS TO HANDLE ERRORS
  const textBoxOutlineName = useMemo(() => {
    return { 
      width: '100%',
      height: '8%',
      minHeight: '8%',
      outline: 'none',
      borderWidth: errorName ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderColor: errorName ? red : lightGray,
      borderStyle: 'solid',
      marginTop: '2.5%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh', 
      textIndent: 10,
      color: black
    }
  }, [errorName])

  const textBoxOutlineMedium = useMemo(() => {
    return { 
      width: '100%',
      height: '85%',
      outline: 'none',
      borderWidth: errorMedium ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderColor: errorMedium ? red : lightGray,
      borderStyle: 'solid',
      marginTop: '2.5%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh', 
      textIndent: 10,
      color: black
    }
  }, [errorMedium])

  const textBoxOutlineValue = useMemo(() => {
    return { 
      width: '100%',
      height: '85%',
      outline: 'none',
      borderWidth: errorPrice ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderColor: errorPrice ? red : lightGray,
      borderStyle: 'solid',
      marginTop: '2.5%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh', 
      textIndent: 10,
      color: black
    }
  }, [errorPrice])

  const textBoxOutlineDescrip = useMemo(()=> {
    return { 
      width: '100%',
      minWidth: '100%',
      height: '30%',
      minHeight: '30%',
      outline: 'none',
      borderWidth: errorDescrip ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      borderColor: errorDescrip ? red : lightGray,
      backgroundColor: 'rgba(120, 153, 137, .1)',
      marginTop: '1%',
      fontFamily:'ApercuProRegular',
      fontSize: '1.8vh', 
      textIndent: 10,
      color: black,
      paddingTop: 15,
      lineHeight: '140%'
    }
  }, [errorDescrip])

  

  return (
    <div style = {containerNavPresent}>

      <StickyNav tab = 'list' userUID = {location.state.uid} pfpSrc={location.state.pfp} disabled = {false}></StickyNav>
      <div style = {smallWhiteContainerNavPresent}> 
        <h1 style = {headerTextNavPresent}>Piece Listing Form</h1>
        <p style = {headerSubNavPresent}>Please provide us with the story and technical details behind the artwork you would like to debut on our platform. This will help our team review your piece effectively and efficiently. </p>

        <div style = {imageAndTextInputFlexSeperator}>

          <div style = {textInputs}> 
            <label style = {label}>Art Title<label style = {labelAsterik}> *</label></label>
            <input type = "text" value={name} onChange = {(event) => setName(event.target.value)} ref = {nameRef} style = {textBoxOutlineName} onClick = {() => setErrorName(false)}/>

            <div style = {inputRowFlexer}> 
                <div style = {rowFlexElement}> 
                  <label style = {label}>Medium<label style = {labelAsterik}> *</label></label>
                  <input type = "text" value={medium} onChange = {(event) => setMedium(event.target.value)} ref = {mediumRef} style = {textBoxOutlineMedium} onClick = {() => setErrorMedium(false)}/>
                </div>
                <div style = {rowFlexElement}>
                  <label style = {label}>Value<label style = {labelAsterik}> *</label></label>
                  <input type = "text" value={price} onChange = {(event) => setPrice(event.target.value)} ref = {priceRef} style = {textBoxOutlineValue} onClick = {() => setErrorPrice(false)}/>
                </div>
            </div>

            <div style = {genreGrid}>
              <label style = {label}>Genres<label style = {labelAsterik}> *</label></label>
              {genres.length > 0  ?
                    <p style = {helperSub}>{genres.join(', ').toString()}</p> 
                    :
                    <p style = {helperSub}>3 genres maximum</p>

                }
              <div style = {genreScroll}>
                {genreList.map((genre, index) => (
                  <div ref = {genreRefs.current[index]} style = {genres.includes(genre) ? genreButtonSelectedBox : genreButtonBox} onClick = {()=> selectGenre(genre, index)} onMouseEnter = {() => hoverGenre(genre, index, 'enter')} onMouseLeave = {() => hoverGenre(genre, index, 'exit')}>{genre}</div> 
                ))}
              </div>
              <p style = {errorGenreText} ref = {noGenreErrorRef}>Please select at least one genre</p>
            </div>

            <label style = {label}>Artist Statement<label style = {labelAsterik}> *</label></label>
            <textarea type = "text" placeholder = "Tell us a little bit more about your art piece..."  value={descrip} onChange = {(event) => setDescrip(event.target.value)} ref = {descripRef} style = {textBoxOutlineDescrip} onClick = {() => setErrorDescrip(false)}> </textarea>

          </div>

          <div style = {imageInput}>
            <label style = {label}>Images<label style = {labelAsterik}> *</label></label>
            <p style = {helperSub}>You can upload up to 4 images.</p>

         
              {images.length > 0 ? 

                <div style = {largeImageContainer}> 
                  <img src={URL.createObjectURL(images[imagePreviewIndex])} style = {imgPreviewLarge}/>
                </div> 
                :
                <div style = {emptyImageContainerLarge}>
                  <img style = {artPlaceholderLarge} src = {artPlaceholder}/>
                </div>
              }
              
         
            <div style = {imgPreviewFlex}>

              <input id = "artImageUploader" type = "file"  multiple onChange={previewFiles} hidden accept='image/*' />  
              <label htmlFor="artImageUploader" style = {{width: '18%', height: '100%'}}>  
                <div style = {uploadButton} ref = {uploadButtonRef}>
                  <img src = {whiteUpload} style = {whiteUploadIcon} />
                </div>
              </label>  
                

                {images[0] != null ?
                  <div style = {imageContainer} onClick = {() => selectPreviewImage(images[0], 0)}>
                    <img style= {imgPreviewSmall} src={URL.createObjectURL(images[0])} alt="artImage" ref = {imgPreviewSelectedRefs.current[0]}/>
                    <p style = {imageRemoveAction} ref = {removePictureRefs.current[0]} onClick = {() => handleRemoveImage(images[0].name)}>remove image</p>
                  </div> 
                  :
                  <div style = {emptyImageContainer}>
                    <img style= {artPlaceholderSmall} src={artPlaceholder} alt="blank image — no upload yet" />
                  </div> 
              
                }
                {images[1] != null ?
                  <div style = {imageContainer} onClick = {() => selectPreviewImage(images[1], 1)}>
                    <img style= {imgPreviewSmall} src={URL.createObjectURL(images[1])} alt="artImage" ref = {imgPreviewSelectedRefs.current[1]}/>
                    <p style = {imageRemoveAction} ref = {removePictureRefs.current[1]}  onClick = {() => handleRemoveImage(images[1].name)}>remove image</p>
                  </div> 
                  :
                  <div style = {emptyImageContainer}>
                    <img style= {artPlaceholderSmall} src={artPlaceholder} alt="blank image — no upload yet" />
                  </div> 
              
                }
                {images[2] != null ?
                  <div style = {imageContainer} onClick = {() => selectPreviewImage(images[2], 2)}>
                    <img style= {imgPreviewSmall} src={URL.createObjectURL(images[2])} alt="artImage" ref = {imgPreviewSelectedRefs.current[2]}/>
                    <p style = {imageRemoveAction} ref = {removePictureRefs.current[2]}  onClick = {() => handleRemoveImage(images[2].name)}>remove image</p>
                  </div> 
                  :
                  <div style = {emptyImageContainer}>
                    <img style= {artPlaceholderSmall} src={artPlaceholder} alt="blank image — no upload yet" />
                  </div> 
              
                }
                {images[3] != null ?
                  <div style = {imageContainer} onClick = {() => selectPreviewImage(images[3], 3)}>
                    <img style= {imgPreviewSmall} src={URL.createObjectURL(images[3])} alt="artImage" ref = {imgPreviewSelectedRefs.current[3]}/>
                    <p style = {imageRemoveAction} ref = {removePictureRefs.current[3]}  onClick = {() => handleRemoveImage(images[3].name)}>remove image</p>
                  </div> 
                  :
                  <div style = {emptyImageContainer}>
                    <img style= {artPlaceholderSmall} src={artPlaceholder} alt="blank image — no upload yet" />
                  </div> 
              
                }

            
            </div>
            <p style = {noImageErrorText} ref = {noImageErrorRef}>Please upload one or more images.</p>
          </div>
        </div>
        <CustomButton type={'dark'} text={"Submit"} callbackfunction = {() => sendPieceApplication()} height = {'5.5%'} width = {'18%'} mLeft = {'2.5%'} mTop = {'2%'} wide = {false} loading = {isLoading}> </CustomButton>

      </div>
    </div>



      
        
  );
}

