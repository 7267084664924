import {background, primary, secondary, orange, altGray, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'


 //^CONTAINER STYLES 
 export const editProfileFlexBox = { 
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '100%',
    backgroundColor: white,
    boxShadow: '0px 10px 10px 0px rgba(120, 153, 137, .1)'
}
export const editProfileBlockOne = { 
    minWidth: '30%',
    width: '30%',
    height: '100%',
    minHeight: '100%',
    borderColor: 'rgba(120, 153, 137, .2)',
    borderWidth: '0px 2px 0px 0px',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
   
   
}
export const editProfileBlockTwo = { 
    width: '70%',
    minWidth: '70%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
}

export const headerTextPP = {
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize:  '2.5vh', 
    textAlign: 'center',
    marginTop: '5%'
}
export const  profilePictureContainer = { 
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'flex-start', 
    alignItems: 'center',
    width: '80%',
    height: '80%',
}
export const photoContainer = {
    width: window.innerWidth >= 1920 ? '190px' :  window.innerWidth >= 1440 ? '175px' :  window.innerWidth >= 1366 ? '150px': window.innerWidth >= 1200 ? '125px' : '125px',
    height: window.innerWidth >= 1920 ? '190px' :  window.innerWidth >= 1440 ? '175px' : window.innerWidth >= 1366 ? '150px': window.innerWidth >= 1200 ? '125px' : '125px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}
export const profilePicture = {
    width: '100%',
    minWidth: '100%',
    borderRadius: '100%',
    height: '100%',
    minHeight: '100%',
}  

export const headerTextInputs = {
    color: black,
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '3vh', 
    textAlign: 'center',
    marginTop: '2.5%',
    marginLeft: '5%'
}

export const inputRowFlexer = { 
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    height: '12%',
    marginLeft: '5%',
    minHeight: '12%',
    marginBottom: 0
}
export const rowFlexElement = { 
    display: 'flex', 
    flexDirection: 'column',
    width: '47%',
    height: '100%'
}
export const labelSmall = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2vh',
    color: black
}
export const label = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2vh',
    color: black,
    marginTop: '2%',
    marginLeft: '5%'
}
export const genreGrid = { 
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5%',
    width: '90%',
    height: '18%',
    minHeight: '18%',
    marginTop: '2.5%',
}
export const genreScroll = { 
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    alignItems: 'center',
    width: '100%',
    height: '55%',
    minHeight: '55%',
    marginTop: '2.5%',
   
}
export const genreButtonBox = { 
    fontFamily: 'ApercuProMedium',
    fontSize: '1.8vh',
    color: accentColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '15%',
    minWidth: '25%',
    maxWidth: '25%',
    height: '90%',
    minHeight: '90%',
    marginRight: '2.5%',
    backgroundColor: white,
    borderColor: accentColor,
    borderWidth: '2px, 2px, 2px, 2px',
    borderStyle: 'solid',
    borderRadius: 28,
    cursor: 'pointer'
}
export const genreButtonSelectedBox = { 
    fontFamily: 'ApercuProMedium',
    fontSize: '1.8vh',
    color: white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '15%',
    minWidth: '25%',
    maxWidth: '25%',
    height: '90%',
    minHeight: '90%',
    marginRight: '2.5%',
    backgroundColor: primary,
    borderColor: primary,
    borderWidth: '2px, 2px, 2px, 2px',
    borderStyle: 'solid',
    borderRadius: 28,
    cursor: 'pointer'
}
export const  helperSub = { 
    fontFamily: 'ApercuProRegular',
    fontSize: '1.6vh',
    color: darkGray,
    marginBottom: 0
}

// export const errorGenreText = { 
//     display: 'none',
//     fontFamily: 'ApercuProRegular',
//     fontSize: '1.6vh',
//     color: 'red',
//     margin: 0,
//     marginBottom: 0,
//     marginTop: '-.5%',
// }
export const buttonSeperator = { 
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5%',
    width: '90%',
    minWidth: '90%',
    marginTop: '2.5%',
    padding: 0,
    minHeight: '7%',
    height: '7%',
    justifyContent: 'space-between',
    alignItems: 'center',
}
export const greenCheckMark = { 
    width: '1.5vw',
    height: '2.6vh',
    paddingLeft: '1%'
}
export const successText = { 
    fontFamily: 'ApercuProRegular',
    fontSize: '1.6vh',
    color: black,
    paddingLeft: '2.5%'
}