import { useNavigate, useLocation } from 'react-router-dom';
// import artistAppBackground from '../assets/backgroundDesign.svg'
import CustomButton from '../components/CustomButton';
import logoBlack from '../assets/logoBlack.png'
import SpecialButton from '../components/SpecialButton';
import {greenContainer, header, container, greenBackground} from '../styles/Globals'
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, altGray, darkGray, textB} from '../styles/Colors'
import { notiContainer, greenSide, headerTextApp, subHeaderTextApp, paragraphApp, textFlexer} from '../styles/ApplicationStatusStyles';
export default function ApplicationAccepted() {

     //^ CONSTANTS:
    const navigate = useNavigate();
    const location = useLocation();


    //& Have contact us button go to email 
    const contactUs = () => { 
        window.location.href = "mailto:ops@unveil.group"
    }
    
    return (

        <div style = {container}>
         
            <div style = {header}>
                <img src = {logoBlack} style = {{width: '5%', height: '5%'}} />
                <CustomButton type={'dark'} text={"Contact Us"} callbackfunction = {() => contactUs()} height = {'5vh'} width = {'12vw'}> </CustomButton>
            </div> 
            <div style = {greenBackground}>
                <div style = {notiContainer}>
                    <div style={greenSide}>
                        <img src = {logoBlack} style = {{width: '52%', height: '25%'}} />
                    </div>
                    <div style = {textFlexer}>
                        <h1 style = {headerTextApp}>Congratulations!</h1>
                        <h2 style = {subHeaderTextApp}>We are delighted to invite you to become an Unveil artist. </h2>
                        <p style = {paragraphApp}>Before you can list specific works on our platform, you will need to fill out your artist profile. Your profile is public to art investors, so consider this a great way to tell your story and convey the deeper themes surrounding your art to your audience.</p>
                        <CustomButton type={'dark'} text={"Complete Profile"} callbackfunction = {() => navigate('/CompleteProfile', {state: {uid: location.state.uid}})}  height = {'11%'} width = {'24%'} mTop = {'2%'} mLeft={'10%'}> </CustomButton>
                    </div> 
                </div>
            </div>
      </div>
    )
}
