import firebase from './Firebase'
import {auth} from './Firebase'
import { useState } from 'react';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, sendPasswordResetEmail, getIdTokenResult, getIdToken, Auth, signOut } from "firebase/auth";
import { collection, doc, setDoc, getDoc, query, orderBy, limit, getDocs, deleteDoc, serverTimestamp, addDoc } from 'firebase/firestore'
import {ref } from "@firebase/storage";
import {storage} from './Firebase'

import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';


// Sign up User (not used)
export async function emailSignUp(email, password) {
    try {
        let instance = getAuth(firebase)
        let res = await createUserWithEmailAndPassword(instance, email, password)
        
        return {
            "success": true,
            "info": res,
            "uid": res.user.uid
        }
    } catch(err) {

        console.log(err)

        let errorMessage = "Unknown Error"

        if(JSON.stringify(err) == "{\"code\":\"auth/invalid-email\",\"message\":\"The email address is badly formatted.\"}") {
            errorMessage = "Invalid Email"
        }
        
        if(JSON.stringify(err) == "{\"code\":\"auth/weak-password\",\"message\":\"Password should be at least 6 characters\"}") {
            errorMessage = "Password should be at least 6 characters"
        }

        return {
            "success": false,
            "errorMessage": errorMessage
        }
    }
}


//& Auth function that logs user in via email
export async function emailLogin(email, password) {

    try { 
        let res = await signInWithEmailAndPassword(auth, email, password)
        return { 
            "success": true,
            "info": res,
            "uid": res.user.uid
        }
    
    //! Catch what the login error was and send that back to front end  
    } catch(err) { 

        var errorType; 
        if (err.message == "Firebase: Error (auth/wrong-password).") errorType = 'wrongPassword'
        else if (err.message == "Firebase: Error (auth/invalid-email).") errorType = 'invalidEmail'
        else {
            errorType = 'misc'
            console.log("😰 unique error occured: " + err.message)
        }
        return {
            "success": false,
            "info": err,
            "type": errorType
        }
    }
}
        

//& Auth function that resets user's password 
export async function resetPassword(email) {

    try {
        let instance = getAuth(firebase)
        let res = await sendPasswordResetEmail(instance, email)
        
        return {
            "success": true,
            "info": res
        }
    
    //! Catch invalid emails 
    } catch(err) { //* can assume every error is just wrong email 

        return {
            "success": false,
            "info": err
        }
    }
}


//& Generate auth token for fetching firestore data 
//todo This code will loop infinitely if there is no user signed in... how can we fix that? 
export const getAuthToken = async() => {
    
    return new Promise(async (resolve, reject) => { //* wait to either resolve or reject

        const unsuscribe = auth.onAuthStateChanged(async user => { //* wait for user to appear from auth

            unsuscribe() 
            const refreshToken = await user.getIdToken(true) //* wait till we actually have a user's token

            //! No token print error 
            //todo Need to do error handling for any edge cases!
            .catch(err => console.error(err)

            ) 
            resolve(refreshToken)
        }, reject)
    })
          
}
export async function logoutUser() {
    try { 
        signOut(auth)
    }
    catch (err) {
        console.log('user could not be logged out')
    }
}



//& Remember Me functionality for artists
export async function rememberCredentialsWithExpiration(key, expiration) {

    await setPersistence(auth, browserLocalPersistence) //* store credentials for 14 days 
    const date = new Date()
    const storageBlock = { 
        startDate: date.getTime(),
        expirationTime: date.getTime() + expiration
    }

    localStorage.setItem(key, JSON.stringify(storageBlock))
}

//& Upload any individual file to firebase
export async function uploadFile(file, folder)  { 
    
    var name = file.name;
    if (!file) return; //* input was not a file
    const storageRef = ref(storage, ('/' + folder + '/' + name));

    try { 
      const uploadTask =  await uploadBytesResumable(storageRef, file);
      return await getDownloadURL(storageRef) //* send back URL 
    }
    catch (err) {
        console.log("Error occured and file could not be uploaded to firebase storage")
        return {
            "status": 'uploadFailure',
            "error": err,
            "message": err.message
        }
    }
 }