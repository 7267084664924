import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link, useLocation} from "react-router-dom"
import StickyNav from '../components/StickyNav';
import NotificationSettings from '../components/NotificationSettings';
import BankAccountSettings from '../components/BankAccountSettings';
import AccountSettings from '../components/AccountSettings';
import EditProfile from '../components/EditProfile';
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
import { containerNavPresent, smallWhiteContainerNavPresent, headerTextNavPresent } from '../styles/Globals';
import {settingsHeaderContainer, headerSubEdit, headerSub, switchScreen} from '../styles/SettingsStyles'
export default function Settings() {


  //^CONSTANTS (NAVIGATION): 
  const navigate = useNavigate();
  const location = useLocation();
  const [currPage, setCurrPage] = useState(0)
  const headerListRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()])

  //& Switch top header 
  const headerSwitch = (newIndex) => {

    if (newIndex != currPage) {

      headerListRefs.current[currPage].current.style.color = black
      headerListRefs.current[currPage].current.style.backgroundColor = 'transparent'
   

      headerListRefs.current[newIndex].current.style.color = white
      headerListRefs.current[newIndex].current.style.backgroundColor = primary
      setCurrPage(newIndex)
    }
 
  }

  return (


    <div style = {containerNavPresent}>
      <StickyNav tab = 'settings' userUID = {location.state.uid} pfpSrc={location.state.pfp} disabled = {false}></StickyNav>
      <div style = {smallWhiteContainerNavPresent}> 
      <h1 style = {headerTextNavPresent}>Settings</h1>

        <div style = {settingsHeaderContainer}>
          <div style = {headerSubEdit} ref = {headerListRefs.current[0]} onClick={() => headerSwitch(0)}>Edit Profile</div>
          <div style = {headerSub} ref = {headerListRefs.current[1]} onClick={() => headerSwitch(1)} >Account</div>
          <div style = {headerSub} ref = {headerListRefs.current[2]} onClick={() => headerSwitch(2)} >Bank Information</div> 
          <div style = {headerSub} ref = {headerListRefs.current[3]} onClick={() => headerSwitch(3)}>Notifications</div>
        </div>

        <div style = {switchScreen}>
          {(() => {
          switch (currPage) {
            case 0:
              return < EditProfile uid = {location.state.uid}></EditProfile>
            case 1:
              return <AccountSettings></AccountSettings>
            case 2:
              return <BankAccountSettings></BankAccountSettings>
            case 3:
              return <NotificationSettings> </NotificationSettings> 
            default:
              return null
          }
          })()}
        </div>

       
          
      </div>
    </div>
   
  )
}


