import {background, primary, secondary, orange, altGray, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'


//^ HOME GLOBALS
export const flexContainer = { 
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    minwidth: '95%',
    minHeight: '85%',
    height: '85%',
    marginLeft: '2.5%',
    marginRight: '2.5%',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

export const subHeaderText = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2.8vh',
    fontColor: black,
    marginLeft: '5%',
    marginBottom: 0,
}
export const graySubTextEmptyData = { 
    fontFamily: 'PPNeueMontrealMedium',
    fontSize: '2.2vh',
    color: darkGray,

    
}
export const  noListingsDiv = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40%',
  
}
export const notisLoadingDiv = { 
    width: '30%',
    height: '30%'
}

//^NOTIFICATIONS
export const notificationsFlex = {
    height: '100%',
    width: '37%',
    minWidth: '37%',
    minHeight: '100%',
    backgroundColor: white,
    boxShadow: '0px 10px 10px 0px #000000',
    boxShadow: '0px -5px 10px 0px rgba(120, 153, 137, .1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 0,
}
export const notificationsScrollView = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    width: '100%',
    marginTop: '5%',
    minHeight: '85%',
    height: '85%',
}

export const notificationsLoadedDiv = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    width: '100%',
    minHeight: '100%',
    height: '100%',
}
export const notificationsPresentAndScrolling = {
    minWidth: '100%',
    width: '100%',
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '-2%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
    alignItems: 'flex-start',
}
export const individualNotification = {
    marginTop: '2.5%',
    marginBottom: '2.5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5%',
    width: '90%',
    minWidth: '90%',
    height: '22.5%',
    minHeight: '22.5%'
}
//^Listings and Applications 
export const listingsAndApplicationsFlex = { 
    display: 'flex',
    width: '61%',
    minWidth: '61%',
    minHeight: '100%',
    height: '100%',
    marginLeft: '2%',
    flexDirection: 'column',
    justifyContent: 'flex-start'
}
export const scrollView = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '85%',
    minHeight: '85%',
    width: '100%',
    height: '100%',
    margin: 0
}
export const listingsAndAppsLoaded = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
}
export const listingsAndAppsPresentAndScrolling = {
    width: '90%',
    minWidth: '90%',
    marginTop: '2.5%',
    height: '95%',
    minHeight: '95%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflowX: 'scroll'
}

export const pieceCard = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '95%',
    minWidth: '30%',
    maxWidth: '30%',
    marginRight: '5%',
    borderColor: 'rgba(120, 153, 137, .25)',
    borderStyle: 'solid',
    borderWidth: '1px, 1px, 1px, 1px',
    borderRadius: '10px'
}
export const pieceCardFinal = {  //! no final marginRight
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '95%',
    minWidth: '30%',
    maxWidth: '30%',
    borderColor: 'rgba(120, 153, 137, .25)',
    borderStyle: 'solid',
    borderWidth: '1px, 1px, 1px, 1px',
    borderRadius: '10px'
}
export const pieceInner = { 
    minWidth: '100%', 
    width: '100%', 
    minHeight: '60%',
    height: '60%', 
    zIndex: 1, 
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'flex-start'
}
export const cardImageContainer = { 
    minWidth: '80%', 
    width: '80%', 
    minHeight: '80%', 
    height: '80%', 
    marginLeft: 'auto', 
    marginRight: 'auto'
}

export const priceDiv = {
    backgroundColor: white,
    color: primary,
    fontFamily: 'ApercuProBold',
    color: black,
    fontSize: '1.5vh',
    zIndex: 2,
    letterSpacing: .5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
    minWidth: '25%',
    height: '15%',
    minHeight: '15%',
    borderColor: white,
    borderStyle: 'solid',
    borderWidth: '1px, 1px, 1px, 1px',
    borderTopLeftRadius: 10,
}

export const pieceHeader = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2.2vh',
    fontColor: black,
    marginLeft: '5%',
}
export const pieceDescription = { 
    fontFamily: 'ApercuProRegular',
    color: black,
    fontSize: '1.6vh',
    lineHeight: '140%',
    marginLeft: '5%',
    marginRight: '2.5%',
    marginTop: 0,
    overflow: 'hidden',
    paddingBottom: '1%',
}
  

//^LISTINGS 
export const listingsFlex = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    minWidth: '100%',
    minHeight: '48%',
    maxHeight: '48%',
    backgroundColor: white,
    boxShadow: '0px 10px 10px 0px #000000',
    boxShadow: '0px -5px 10px 0px rgba(120, 153, 137, .1)'
}


//^APPLICATIONS
export const applicationsFlex = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '4%',
    width: '100%',
    minWidth: '100%',
    minHeight: '48%',
    height: '48%',
    backgroundColor: white,
    boxShadow: '0px 10px 10px 0px #000000',
    boxShadow: '0px -5px 10px 0px rgba(120, 153, 137, .1)',
  
}
  