
import { initializeApp} from 'firebase/app';
import { getStorage } from "firebase/storage";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, browserLocalPersistence, sendPasswordResetEmail, getIdTokenResult, getIdToken} from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyBZf_ex7k9X15pQCarzGof8SRql7Err6ww",
    authDomain: "artisynapp.firebaseapp.com",
    projectId: "artisynapp",
    storageBucket: "artisynapp.appspot.com",
    messagingSenderId: "1017241076029",
    appId: "1:1017241076029:web:197ed2f85f120f11474554",
    measurementId: "G-CL3XLFVR28"
  };

const firebase = initializeApp(firebaseConfig)
export const storage = getStorage(firebase); 
export const auth = getAuth(firebase);

export default firebase
