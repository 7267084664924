import React from 'react'
import Footer from '../components/Footer'
import privacyPolicy from '../assets/PRIVACYPOLICY.pdf'

export default function PrivacyPolicy() {
  return (

    <object data = {privacyPolicy} style = {{width: '100%', height: '100vh'}}> </object>

    )
}



    // <div>
    //      <div style = {header}>
    //           <img src = {logoGreen} style = {{width: '5%', height: '5%'}} />
    //           <CustomButton type={'dark'} text={"Artist Console"} callbackfunction = {() => artistConsolePath()} height = {'5vh'} width = {'12vw'}> </CustomButton>
            
    //       </div>  

    //       <div style = {greenBackground}>
    //         <div style = {whiteTOS}>
    //             <h1 style = {title}>PRIVACY POLICY</h1>
    //             <p style = {date}>Effective September 25th, 2023</p>
    //             <p style = {body}>This privacy policy <span style = {underlineText}>("Privacy Policy")</span>describes how Unveil Inc., Unveil Portal
    //             LLC, Unveil Curators LLC, Unveil Holding Ltd, and Unveil Art Management LLC
    //             (<span style = {underlineText}>"Unveil"</span> <span style = {underlineText}> "we"</span> <span style = {underlineText}> "us"</span> or <span style = {underlineText}> "our"</span>) collect, use, and share information about users
    //             of our websites, including unveil.group, our Apps and our associated services
    //             (collectively, our <span style = {underlineText}>"Site"</span> and <span style = {underlineText}>"Services"</span>). We are committed to protecting and
    //             respecting your privacy. To understand more about the roles we carry out in
    //             handling personal information, please see the Contact Us section. By using our
    //             Site and Services, you acknowledge you have read and understood this Privacy
    //             Policy and our practices described below. Your use of our Site and Services is
    //             also subject to our Terms of Use. Please note that any user information shared
    //             with one of our entities may be shared among the other Unveil entities.</p>

    //             <p style = {body}> <span style = {underlineText}>Information We Collect about you</span> 
    //             <br/>
    //             Information you provide us. 
    //             <br />
    //             When you use our Site, our Services, or otherwise choose to interact with us,
    //             we receive personal information about you, such as when you register for and
    //             create a profile on our Site. Personal information means information from which
    //             you may be identified directly (from that information alone) and indirectly
    //             (when different pieces of information are combined).
    //             </p>
                
    //             <p style = {body}>We may collect and process the following personal information from you:</p>

    //             <ul style = {orderedList}> 
    //                 <li style = {listElement}><span style = {underlineText}>Name and contact.</span>
    //                     This information may include your name, address, phone
    //                     number, email address, and social media handle that you provide if you
    //                     fill in forms on our Site or otherwise contact us.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Account login details.</span>
    //                 If you choose to sign up and create an account, we
    //                 will collect your name, chosen username, and will utilize Firebase to handle password encryption.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Account profile details.</span>
    //                 On your Unveil profile, you can make additional
    //                 optional personal information available, including a photo, biography,
    //                 links to a personal website or blog, details of your skills, investment
    //                 goals and interests, and other similar information.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Identity details.</span>
    //                 We may need to collect certain information to verify
    //                 your identity, including your name, date of birth, applicable tax or
    //                 other national-issued identifiers, Social Security number, and /or other
    //                 legally recognized identity documentation such as your driver's license.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Investor details.</span>
    //                 To ensure compliance with legal requirements, we may
    //                 need to collect your net worth, income, and other information necessary
    //                 to verify your investor accreditation or sophistication status.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Investment transacation details.</span>
    //                 If you choose to invest in an offering
    //                 through our Site, we will collect payment information from you, such as
    //                 financial account or payment card information, and we may collect
    //                 information to verify your income, such as a bank statement or W-2 form.
    //                 Please note that we do not store your payment card information, but we do
    //                 store other financial information. If you add an investment entity to
    //                 your Account profile, we will also collect information about the entity.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Founder Business details.</span>
    //                 If you would like to raise funding for your
    //                 business with our Site and Services, we will collect information about
    //                 your business, including other officers and stockholders, business
    //                 financial statements, your fundraising terms, and payment and banking
    //                 information.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Compliance information.</span>
    //                 We may need to request information regarding
    //                 business legal and regulatory risk checks.</li>
    //             </ul>
    //             <p style = {body}>We also collect information when you post questions or comments on our Site,
    //             send or receive direct messages from other users, or when you contact us,
    //             including via email or social media.</p>
    //             <p style = {body}><span style = {boldText}>We will indicate whether information is required or voluntary on the Site.</span>  
    //             When you visit our Website. <br/> 
    //             <br />
    //             <span style = {boldText}>We may automatically collect certain information when you use our Site, including:</span>
    //             </p>
    //             <ul style = {orderedList}> 
    //                 <li style = {listElement}><span style = {underlineText}>Interaction information</span>
    //                 about your use of our Site, including the full
    //                 Uniform Resource Locators (URL) clickstream to, through, and from our
    //                 Site, including the actions you take, the pages or other content you
    //                 view, search for, or otherwise interact with content response times, and
    //                 the dates and times of your visits.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Technical and device information</span>
    //                 such as your IP address used to connect
    //                 your device to the internet, operating system information, web browser
    //                 and /or device type, version, time zone setting, and language.</li>

    //                 <li style = {listElement}><span style = {underlineText}>Location information.</span>
    //                 We may derive your regional location from your IP address.</li>

    //                 <li style = {listElement}><span style = {underlineText}>Cookies and similar technologies:</span>
    //                 When you visit our Site, we and our service providers collect certain information through the use of
    //                 "cookies" and other similar technologies to better understand how our
    //                 users navigate through our Site, learn what content is being viewed, and
    //                 administer and improve our Site and Services. Cookies are small text
    //                 files that web servers place on an internet user's device designed to
    //                 store basic information and recognize your browser. We use both session
    //                 cookies and persistent cookies. A session cookie disappears after you
    //                 close your browser. A persistent cookie remains after you close your
    //                 browser and may be used by your browser on subsequent visits to our Site.
    //                 We use cookies /and other similar technologies either alone or in
    //                 combination with each other to create a unique ID that corresponds to
    //                 you. For more information, see the <span style = {underlineText}>'Use of cookies and other
    //                 technologies'</span> section below.</li>

    //             </ul>

    //             <p style = {boldText}>Information from other sources.</p>
    //             <ul style = {orderedList}> 
    //                 <li style = {listElement}><span style = {underlineText}>Social Media:</span>
    //                 If you make your personal information publicly available,
    //                 such as information posted to social media sites when you tag us or
    //                 otherwise interact with our social media channels and posts, for example,
    //                 by liking a post, we may collect personal information such as your social
    //                 media profile name and picture or content you post, like and reactions to
    //                 your posts.</li>
    //                 <li style = {listElement}><span style = {underlineText}>Third-Party Integration:</span>
    //                 If you decide to register through or otherwise
    //                 grant access to a third-party social networking or authentication service
    //                 that we may make available ("Integrated Service"), such as Facebook,
    //                 LinkedIn, Thread, Twitter, or Gmail, we will collect certain personal
    //                 information that you have provided to the Integrated Service (such as
    //                 your name, email address, photo, and other information you make available
    //                 via the Integrated Service) and an authentication token from the
    //                 Integrated Service. The personal information collected from the
    //                 Integrated Service may be used to register you on our Site and to provide
    //                 some of the features of our Site. You may revoke our access to your
    //                 account on the Integrated Service at any time by updating the appropriate
    //                 settings in the account preferences of the respective Integrated Service.
    //                 You understand that certain features of our Site may not be available to
    //                 you if you choose to remove our access to your account with the
    //                 Integrated Service.</li>

    //                 <li style = {listElement}>In order to provide the Services on our Site, including verifying your
    //                 identity and other information you provide us, or in conducting any
    //                 required checks (including in the context of checks against international
    //                 sanctions, exposed persons, or export control registers), we collect
    //                 information about our users from third parties KYC platforms, such as
    //                 Blockscore, and Onfido, then combine it with other information we
    //                 received from or about our users.</li>
    //             </ul>
    //             <p style = {body}> <span style = {boldText}>How we use the Information we collect</span>
    //             <br/> We may use the information we collect for the following purposes:
    //             <br/> <br/><span style = {boldText}>Information you provide us</span></p>
                
    //             <ul style = {orderedList}>
    //                 <li style = {listElement}>To take steps to enter into any contract or carry out obligations arising out of our terms with you, including to:</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>Administer your account with us.</li>
    //                     <li style = {listElement}>Process payments and investments.</li>
    //                     <li style = {listElement}>Notify you about changes to our Services.</li>
    //                     <li style = {listElement}>Keep a record of transactions.</li>
    //                 </ul>
    //                 <li style = {listElement}>To comply with legal obligations to which we are subject, including:</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>Identify business and/or verification checks.</li>
    //                     <li style = {listElement}>Record-keeping requirements.</li>
    //                     <li style = {listElement}>Cooperating with regulatory authorities.</li>
    //                 </ul>
    //                 <li style = {listElement}>Where necessary in our legitimate interests</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>In providing the information you request from us, including responding to any queries you have submitted.</li>
    //                     <li style = {listElement}>in managing corporate risk, security, and compliance operations.</li>
    //                     <li style = {listElement}>to enforce this Privacy Policy or other terms to which you have agreed, and to protect the rights, property, 
    //                     or safety of us, ourSite, our users, or any other person.</li>
    //                 </ul>
    //                 <li style = {listElement}>With your consent, where required, for example, to send you our marketing communications</li>
    //                 <li style = {listElement}>Unless we are required to obtain your consent, we will use this information in our legitimate interests where we have considered your rights do not override these to:</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>Provide, improve, and personalize our Site</li>
    //                     <li style = {listElement}>Monitor and analyze usage trends and preferences.</li>
    //                     <li style = {listElement}>Keep our Site and Services safe and secure.</li>
    //                     <li style = {listElement}> Measure or understand the effectiveness of our communications or advertising to you and others and deliver relevant advertising to you.</li>              
    //                 </ul>
    //                 <li style = {listElement}>Information from other sources</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>Unless we are required to obtain your consent, we will use thisinformation in our legitimate interests where we have considered your rights do not override these:</li>
    //                     <ul style = {nestedList}>  
    //                         <li style = {listElement}> We may use information obtained via social media channels for
    //                         our own analysis and marketing purposes.</li> 
    //                     </ul>
    //                 </ul>
    //                 <li style = {listElement}> Use of Cookies and other Technologies Necessary Cookies</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>Stripe - We use Stripe to handle payment processing. Stripe uses cookies to authenticate users and protect against fraud.</li>
    //                     <li style = {listElement}>Plaid - We use Plaid to connect and verify bank accounts.</li>
    //                     <li style = {listElement}>Unveil First-Party Cookies - We use these cookies for user login.</li>
    //                     <li style = {listElement}>Cloudflare - We use this for attack adn spma prevention.</li>
    //                     <li style = {listElement}>Sift - We use Sift to help us detect spam and fraud.</li>                
    //                 </ul>
    //                 <li style = {listElement}>Analytics cookies</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}> Google Analytics cookies enable us to recognize and count the
    //                     number of visitors and to see how they engage with our Site content
    //                     and features. This helps us for our legitimate interests of
    //                     improving how our Site works to provide you with the most relevant
    //                     content. For example, ensuring that users find what they are
    //                     looking for easily. You can find more information on how Google
    //                     uses data from these cookies, including about how to opt-out at the
    //                     below links:
    //                     <a style = {linkElement} href="https://google.com/policies/privacy/partners/.https://tools.google.com/dlpage/gaoptout">https://google.com/policies/privacy/partners/.https://tools.google.com/dlpage/gaoptout</a></li>
    //                     <li style = {listElement}>Fullstory - Analytics cookies enable us to see a visual
    //                     representation of how users are interacting with our Site. This
    //                     helps us in our legitimate interests of fixing technical issues and
    //                     improving the operation of our Site. You can find more information
    //                     on how Fullstory uses data from these cookies, including about how
    //                     to opt-out at the below links:
    //                     <a style = {linkElement} href="https://fullstory.com/legal/privacy-policyhttps://fullstory.com/optout/">https://fullstory.com/legal/privacy-policyhttps://fullstory.com/optout</a></li>
    //                     <li style = {listElement}>Hubspot - We use Hubspot to track engagement by visitors to the
    //                     Site and to manage inquiries from our Site and our relationships
    //                     with our founders and investors for our CRM platform. You can find
    //                     more information on how Hubspot uses data from these cookies,
    //                     including about how to opt-out at the below link:
    //                     <a style = {linkElement} href="https://fullstory.com/legal/privacy-policyhttps://fullstory.com/optout/">https://legal.hubspot.com/privacy-policy</a></li>
                                 
    //                 </ul>
    //                 <li style = {listElement}>Advertising cookies</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}> Google advertising cookies are used to show you our ads on other
    //                     websites. These ads may be directed to you based on your past
    //                     visits to our Site and your use of our Site. You can find more
    //                     information on how Google uses data from these cookies, including
    //                     about how to opt-out at the below links:
    //                     <a style = {linkElement} href="https://google.com/policies/privacy/partners/.https://adsettings.google.com/">https://google.com/policies/privacy/partners/.https://adsettings.google.com/</a></li>
    //                 </ul>
    //                 <li style = {listElement}>Social Networking Cookies <br /> These cookies help us to manage our social media, including enabling you to share content and enabling us to understand how you interact with us.</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>  Facebook - We use Facebook cookies and pixels to help deliver our
    //                     advertising on Facebook. This means you may see our ads when you
    //                     use Facebook because you have visited and then visited Facebook.
    //                     These cookies also tell us if you come to our Site after clicking
    //                     on an advertisement on Facebook. We also work with Facebook to
    //                     deliver our ads to Facebook users who match specific criteria that
    //                     Facebook identifies. Please visit your Facebook privacy settings to
    //                     learn more and see here: 
    //                     <a style = {linkElement} href="https://facebook.com/policy.php">https://facebook.com/policy.php</a></li>
    //                     <li style = {listElement}>Twitter - We use Twitter cookies to deliver our ads on the Twitter
    //                     platform to you and those other Twitter users who might be
    //                     interested in our Services based on their online activities,
    //                     including on the Twitter platform. Please visit your Twitter
    //                     privacy settings to configure how ads are displayed to you and also
    //                     see here 
    //                     <a style = {linkElement}  href="https://facebook.com/policy.php">https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads.</a></li>
    //                 </ul>
    //                 <li style = {listElement}>You can opt out of our service provider's use of cookies for directedmarketing purposes by visiting the US Network Advertising Initiative's opt-out page at
    //                 <a style = {linkElement}  href="https://optout.networkadvertising.org/">https://optout.networkadvertising.org/</a>, the Digital
    //                 Advertising Alliance's consumer choice tool at <a style = {linkElement}  href="https://optout.aboutads.info. ">https://optout.aboutads.info.</a> 
    //                 Please note that after opting-out, you may continue to see ads from those service providers, but they will not be based on your past activities.
    //                 <br /> 
    //                 <br />
    //                 You may choose to disable some cookies. The effect of disabling cookies
    //                 depends on which cookies you disable. Please note that if you delete or
    //                 choose not to accept all cookies from our Site, you may be unable to
    //                 utilize certain features of our Site to their fullest potential. You can
    //                 also consult your web browser settings to learn how to modify cookie
    //                 settings.</li>
    //             </ul>
    //             <p style = {body}> <span style = {boldText}>How We Share Information We Collect</span>
    //             We may make information available to:
    //             <br/> <br/><span style = {boldText}>Artwork You Invest In:</span>
    //             <br/> <br/>
    //             We may share your information with the artist whose artwork you invest
    //             in. The information about you we share with the artwork you invest in may
    //             include your public profile information and email address.
    //             <br/> <br/>
    //             <span style = {boldText}>Other Users and the Public</span>
    //             </p>
    //             <ul style = {orderedList}> 
    //                 <li style = {listElement}>
    //                 Please note that your profile page is viewable by other users and
    //                 the public. For information on privacy settings available to you,
    //                 please see the "Account Settings Choices" section below.</li>
    //                 <li style = {listElement}>
    //                 Any comments or information you submit or share on public areas of
    //                 the Site, such as questions for artists or your bio, can be read,
    //                 collected, or used by other users and the public. Please consider
    //                 carefully the information you choose to submit or share on the
    //                 public areas of our Site, such as investment pages or your public
    //                 profile.</li>
    //                 <li style = {listElement}>
    //                 We also share your information with other users when you send or
    //                 receive direct messages from other users.</li>
    //                 <li style = {listElement}>
    //                 Affiliates and Banks. To provide the services on our Site, we may
    //                 share your information with other third parties, such as artists,
    //                 escrow account providers, investment banking firms, accountants,
    //                 and similar entities.</li>
    //                 <li style = {listElement}>
    //                 We use service providers to provide certain services to us on our
    //                 behalf and in accordance with our instructions. This includes
    //                 supporting our Services, particularly those providing Site data
    //                 hosting services, fulfillment services, distributing any
    //                 communications we send, fraud detection, facilitating feedback on
    //                 our Services, advertising, marketing, analytics services, and
    //                 providing IT support services from time to time.</li>
    //                 <li style = {listElement}>
    //                 Payment processing providers secure payment and bank transfer
    //                 processing services. Note that your payment card details are
    //                 securely processed by the provider and in no way held by us.</li>
    //             </ul>

    //             <p style = {body}>We only provide our service providers and partners with information
    //             necessary for them to perform these services. Our analytics providers may
    //             collect information about your online activities over time and across
    //             different online services when you use our Site.
    //             Change of control. <br /> <br />
    //             If Unveil is merged or sold, or in the event of a transfer or sale of
    //             some or all of our assets, we may disclose or transfer information we
    //             collect in connection with the negotiation and/or conclusion of such a
    //             transaction.</p>
    //             <p style = {body}>Other disclosures. 
    //             We may disclose information about you to third parties if (a) we believe
    //             that disclosure is reasonably necessary to comply with any applicable
    //             law, regulation, legal process or governmental request, (b) to enforce
    //             our agreements, policies or terms of service, (c) to protect the security
    //             or integrity of our Site and Services, (d) to protect the property
    //             rights, and safety of us, our users, or the public, (e) to respond in an
    //             emergency which we believe in good faith requires us to disclose
    //             information to assist in preventing the death or serious bodily injury of
    //             any person, or (f) to investigate and defend ourselves against any
    //             third-party claims or allegations.
    //             <br /> <br />
    //             <span style = {boldText}>Your Account Settings Choice:</span>
    //             You can update the information you provided by accessing your account
    //             settings. Through your account settings, you can also hide some portions
    //             of your profile from other users or the general public on the Internet,
    //             as well as hide your profile from search engines that collect information
    //             from our Sites.
    //             <br /> <br />
    //             You can also opt out of promotional or marketing email communications,
    //             including affiliate marketing, by clicking on the unsubscribe link in any
    //             email you receive from us, by updating your communication preferences
    //             through your account settings on our Site, or by contacting us at
    //             <a href = "mailto: support@unveil.group">support@unveil.group</a>
    //             <br /> <br />
    //             See also  <span style = {underlineText}>'Your Rights'</span> below.
    //             <br /> <br />
    //             <span style = {boldText}>Links to Other Websites:</span>
    //             <br /> <br />
    //             Our Site contains links to websites maintained by third parties. Please
    //             be aware that these third-party websites are governed by their own
    //             privacy policies and are not covered by our Privacy Policy. We are not
    //             responsible for the content or policies maintained by these websites.
    //             Please familiarize yourself with the privacy policy of any third-party
    //             websites you visit.
    //             <span style = {boldText}>Security of Your Information:</span>
    //             The security of your information is important to us. We have implemented
    //             security measures to help protect the information in our care. We make
    //             use of physical, electronic, and organizational measures in an effort to
    //             safeguard and prevent unauthorized access.<br /> <br /></p>

    //             <ul style = {orderedList}> 
    //                 <li style = {listElement}>All areas of our Site use SSH technology. Any payment transactions will be encrypted using SSL technology.</li>
    //                 <li style = {listElement}>Where you have chosen a password that enables you to access certain
    //                 parts of our Site and Services, you are responsible for keeping
    //                 this password confidential. We ask you not to share a password with
    //                 anyone.</li>
    //                 <li style = {listElement}>We take steps to limit access to your information only to those who
    //                 need access.</li>
    //                 <li style = {listElement}>We take steps to contractually require our service providers to
    //                 protect any personal information they process on our behalf.</li>
    //                 <li style = {listElement}> Unfortunately, the transmission of information via the Internet is not
    //                 100% secure. Although we do our best to protect your personal
    //                 information, we cannot guarantee the security of your data transmitted to
    //                 our Site; any transmission is at your own risk. Once we have received
    //                 your information, we will use strict procedures and security features to
    //                 prevent unauthorized access.</li>
    //             </ul>
    //             <p style = {body}>
    //                 <span style = {boldText}>Children's Privacy:</span>
    //                 Our Site is not intended for children under the age of 13, and we do not
    //                 knowingly collect personal information from children under the age of 13.
    //                 If we learn that we have collected personal information from a child
    //                 under 13, we will take steps to delete the data. If you know a child
    //                 under 13 has provided us with personal information, please contact us at <a href = "mailto: privacy@unveil.group">privacy@unveil.group</a>
    //                 <br /> <br />
    //                 <span style = {boldText}>Internal Data Transfers:</span>
    //                 Your personal information will processed in accordance with this policy
    //                 in the U.S. and Europe. Where you are resident in Europe, we handle your
    //                 personal data outside of the European Economic Area (EEA), and where we
    //                 facilitate access to or transfer of your personal information outside of
    //                 the EEA, we will take steps to handle your personal data subject to
    //                 appropriate safeguards, for example, implementing approved standard
    //                 contractual clauses where appropriate as the laws regarding processing of
    //                 personal information may be less stringent than the laws in the EEA.
    //                 Third parties that we work with may also process your personal
    //                 information in the U.S. and/or facilitate the transfer of or access to
    //                 your personal data outside of the U.S. We will take steps to require them
    //                 to process your personal information subject to appropriate safeguards,
    //                 for example, implementing standard contractual clauses where applicable.
    //                 <br /> <br />
    //                 <span style = {boldText}>How Long we Keep your Information:</span>
    //             </p>
    //             <ul style = {orderedList}> 
    //                 <li style = {listElement}>We retain personal information for as long as we are legally
    //                 required or otherwise permitted to meet our reason for collection
    //                 (for example, for as long as we are providing Services to you) and
    //                 as necessary after that to anticipate, resolve, or defend any
    //                 issues.</li>
    //                 <li style = {listElement}>Relating to any account or transactions with us to meet our
    //                 contractual obligations to you and/or any transaction to identify
    //                 any issues, resolve any legal proceedings, and for tax and
    //                 accounting purposes.</li>
    //                 <li style = {listElement}>We may also retain aggregate information for research purposes and
    //                 to help develop and improve our services.</li>
    //             </ul>
    //             <p style = {boldAndUnderlineText}>Your Rights</p>
    //             <ul style = {orderedList}> 
    //                 <li style = {listElement}>Depending on the country you access our Site and Services from, you
    //                 may have the right under certain circumstances:</li>
    //                 <ul style = {nestedList}>
    //                     <li style = {listElement}>to find out what information we hold about you;</li>
    //                     <li style = {listElement}>to be provided with a copy of your personal data;</li>
    //                     <li style = {listElement}>to request the correction or deletion of your personal data;.</li>
    //                     <li style = {listElement}>to request that we restrict the processing of your personal
    //                     information (while we verify or investigate your concerns
    //                     with this information, for example);</li>
    //                     <li style = {listElement}>to object to the further processing of your personal
    //                     information, including the right to object to marketing (as
    //                     mentioned in the 'Your Account Settings Choices' section);</li>
    //                     <li style = {listElement}> to request that your provided personal information be moved
    //                     to a third party;</li>
    //                 </ul>
    //                 <li style = {listElement}>You can un-install our App, which will stop information being
    //                 collected from the App. You may use the standard uninstall
    //                 processes as may be available for your mobile device.</li>
    //                 <li style = {listElement}>Where the processing of your personal information by us is based on
    //                 consent, you have the right to withdraw that consent without
    //                 detriment at any time.</li>
    //             </ul>
    //             <p style = {body}> If you wish to exercise any of the rights listed above that are available to
    //             you, you can do so at any time by contacting us at <a href = "mailto: support@unveil.group">support@unveil.group</a> and the data protection team
    //             will respond according to the applicable law.

    //             <br /> <br /> <br />
    //             <span style = {boldText}>Changes to Our Privacy Policy:</span>
    //             Please revisit this page periodically to stay aware of any changes to our
    //             Privacy Policy, which we may update from time to time. If we modify our Privacy
    //             Policy, we will make it available on our Site and indicate the effective date.
    //             <br /> <br />
    //             If the modifications materially alter your rights or obligations, we will make
    //             reasonable efforts to notify you of the change. For example, we may send a
    //             message to your email address, if we have one on file, or generate a pop-up or
    //             similar notification when you access our Site for the first time after such
    //             material changes are made. Your continued use of our Site after the revised
    //             Privacy Policy has become effective indicates that you have read and understood
    //             the current version of our Privacy Policy.
    //             <br /> <br />
    //             <span style = {boldText}>Contacting Us:</span>
    //             <br /> <br />
    //             <span style = {underllineText}>Unveil Inc.,</span>a controller that administers and hosts the Site and from which
    //             global and strategic decisions are made as the parent company of the Unveil
    //             business, 701 Tillery Street Unit 12-1076, Austin, Texas 78702, United States
    //             Unveil Portal LLC,
    //             <br /> <br />
    //             If you have any questions, comments, or concerns about our Privacy Policy,
    //             please email us at <a href = "mailto: privacy@unveil.group">privacy@unveil.group</a>
    //             </p>
    //         </div>
    //       </div>
    //       <Footer> </Footer>
    // </div>

