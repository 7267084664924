import React, { useRef } from 'react'
import appstore from '../assets/appstore.png'
import instagram from '../assets/instagram.png'
import linkedin from '../assets/linkedin.png'
import logoWhite from '../assets/logoWhite.png'
import logoBlack from '../assets/logoBlack.png'
import { useNavigate} from 'react-router'
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'

export default function Footer() {

    const navigate = useNavigate()
    const pp = useRef()
    const terms = useRef()
  return (
    <div style = {footerBackground}>
        <div style = {footerFlexer}>

            <div style = {blockOne}> 
                <div style = {innerBlock}> 
                    <p style = {smallInfoText}>Unveil</p>
                    <p style = {smallInfoText} onClick={() => navigate('/PrivacyPolicy')} onMouseEnter={() => pp.current.style.textDecoration = 'underline'} onMouseLeave={() => pp.current.style.textDecoration = 'none'} ref = {pp}>Privacy Policy</p>

                </div>
                <div style = {innerBlock}> 
                    <p style = {smallInfoText}>701 Tillery Street</p>
                    <p style = {smallInfoText} onClick={ () => navigate('/TermsAndConditions')} onMouseEnter={() => terms.current.style.textDecoration = 'underline'} onMouseLeave={() => terms.current.style.textDecoration = 'none'}ref = {terms}>Terms of Service</p>
                </div>
                <div style = {innerBlock}>
                    <p style = {smallInfoText}>Austin, TX 78702</p>
                    <p style = {smallInfoText}>(585) 953-8053</p>
                </div>
            </div>

            <div style = {blockThree}>
                <img  src = {logoBlack} style = {blackLogo} />
            </div>
        </div>

        <div style = {divider}> </div>
        <p style = {legalDisclaimer}>Unveil @ 2023. All rights reserved. </p>
    </div>
  )
}


//^STYLES FOOTER
const footerBackground = {
    background: white,
    width: '100%',
    height: '20vh',
    marginBottom: '0%',
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '2.5%',
    boxShadow: '2px 4px 4px 0px rgba(0, 0, 0, .25)',
    boxShadow: '2px -5px 10px 0px rgba(120, 153, 137, .1)',
}
const footerFlexer = {
    display: 'flex',
    width: '90%',
    height: '70%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '2%',
    marginBottom: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
}
const blockOne = { 
    cursor: 'pointer',
    width: window.innerWidth  > 1024 ? '40%' : '60%',
    display: 'flex',
    minHeight: '50%',
    height: '90%',
    marginBottom: '2.5%',
    flexDirection: 'column',
    justifyContent: 'flex-start'

    // border: '2px solid red'
}
const innerBlock = { 
    display: 'flex',
    flexDirection: 'row',

    padding: 0,
    height: '33.33333%',
    marginBottom: 0,
    justifyContent: 'center',
    alignItems: 'flex-start'
}
const blockThree = { 
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
}

const smallInfoText = { 
    color: black, 
    fontFamily: 'ApercuProMedium',
    fontSize: '2vh',
    lineHeight: '140%',
    width: '100%',
}
const blackLogo = { 
    width: '5vw',
    height: '8vh',
}


const divider = { 
    minHeight: '1%', 
    width: '90%', 
    marginLeft: 'auto', 
    backgroundColor: black, 
    marginRight: 'auto' 
} 
const legalDisclaimer = { 
    color: black,
    fontFamily: 'ApercuProRegular',
    fontSize: '1.8vh',
    marginLeft: '5%',
    marginBottom: '2.5%'
}
