import React from 'react'
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
import { container, montrealSubHeader } from '../styles/Globals'
export default function BankAccountSettings() {


  return (
    <div style = {container}>
        <h1 style = {montrealSubHeader}>Coming Soon!</h1>
    </div>
  )
}


