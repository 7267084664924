import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'



export const loginContainer = { 
    backgroundColor: white,
    width: '40%',
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: '1px 1xp 1px 1px', 
    borderColor: lightGray, 
    borderStyle: 'solid'
}
export const title = {
    fontFamily: 'PPNeueMontrealBold', 
    color: black, 
    fontSize: '4.5vh', 
    marginTop: '7.5%'
}
export const subText = {
    fontFamily: 'ApercuProRegular', 
    fontSize: '1.8vh', 
    color: black, 
    textAlign: 'center',
    width: window.innerWidth > 1300 ? '80%' : '90%', 
    lineHeight: '140%', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    marginTop: '-2%'
}
export const inputFlex = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '70%',
    height: '65%',
}
export const inputRowFlexer = { 
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '15%',
    marginBottom: 0
}
export const rowFlexElement = { 
    display: 'flex', 
    flexDirection: 'column',
    width: '47%',
    height: '100%'
}
export const textBoxOutline = { 
    width: '100%',
    height: '9%',
    outline: 'none',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    backgroundColor: 'rgba(120, 153, 137, .1)',
    borderBottomWidth: 1,
    borderColor: lightGray,
    marginTop: '1%',
    fontFamily:'ApercuProRegular',
    fontSize: '1.8vh',
    textIndent: 10,
    color: black,
   
}
export const textBoxOutlineLong = {
    width: '100%',
    height: '18%',
    outline: 'none',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    backgroundColor: 'rgba(120, 153, 137, .1)',
    borderBottomWidth: 1,
    borderColor: lightGray,
    marginTop: '1%',
    fontFamily:'ApercuProRegular',
    fontSize: '1.8vh',
    textIndent: 10,
    color: black,
    paddingTop: 15,
    lineHeight: '110%',
    marginBottom: 0
}  
export const labelText = { 
    color: darkGray,
    fontFamily: 'ApercuProRegular',
    padding: 0,
    fontSize: '1.8vh',
    marginTop: '5%',
    marginBottom: 0,
    width: '100%'
}
export const optional = { 
    color: darkGray,
    fontFamily: 'ApercuProItalic',
    padding: 0,
    fontSize: '1.8vh',
    marginBottom: 0
}