import {background, primary, secondary, orange, altGray, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'


export const settingsHeaderContainer = {
    width: '95%',
    minWidth: '95%',
    marginTop: '-1%',
    marginLeft: '2.5%',
    height: '6%',
    minHeight: '6%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: 'rgba(120, 153, 137, .1)',
    borderRadius: '40px',
   
  }
export const headerSubEdit = { 
    color: white,
    backgroundColor: primary,
    fontSize: window.innerWidth > 1450 ? 20 : window.innerWidth > 1250 ? 18 : 16,
    FontFamily: 'ApercuProMedium',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: '100%',
    width: '30%',
    fontWeight: 500,
    borderRadius: '40px'
  }
export const headerSub = { 
    color: black,
    height: '100%',
    borderRadius: '40px',
    width: '30%',
    backgroundColor: 'transparent',
    fontSize: window.innerWidth > 1450 ? 20 : window.innerWidth > 1250 ? 18 : 16,
    FontFamily: 'ApercuProMedium',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    justifyContent: 'center',
    cursor: 'pointer',
  }
export const switchScreen = { 
    height: '78%',
    minHeight: '78%',
    width: '85%',   
    minWidth: '75%',
    marginTop: '2.5%',
    marginBottom: '2.5%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
   
  }