import React from 'react'
import { useRef, useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logoWhite from '../assets/logoWhite.png'
import logoDisabled from '../assets/logoDisabled.png'
import user from '../assets/user.png'
import userDisabled from '../assets/userDisabled.png'
import settings from '../assets/settings.png'
import settingsDisabled from '../assets/settingsDisabled.png'
import home from '../assets/home.png'
import homeDisabled from '../assets/homeDisabled.png'
import metrics from '../assets/metrics.png'
import metricsDisabled from '../assets/metricsDisabled.png'
import list from '../assets/list.png'
import listDisabled from '../assets/listDisabled.png'
import bell from '../assets/bell.png'
import bellDisabled from '../assets/bellDisabled.png'
import { logoutUser } from '../database/AuthFunctions';
import { primary, secondary, accentColor, white, black, green, red, orange, disabledAccent, darkGray } from '../styles/Colors'


const StickyNav = ({tab, userUID, pfpSrc, disabled}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const logoutRef = useRef()
    const [isPicClicked, setPicClicked] = useState(false);
    const [isSettingsClicked, setSettingsClicked] = useState(false);
    const isSettingsScreen = location.pathname === '/settings';


    //^ CHANGING STYLES 
    const navTextBoxHome = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '20%',
        backgroundColor: tab=='home' ? 'rgba(255, 255, 255, .4)' : 'none',
        cursor: 'pointer'
    }

    const navTextBoxMetrics = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '20%',
        backgroundColor: tab=='metrics' ? 'rgba(255, 255, 255, .4)' : 'none',
        cursor: 'pointer'
    }

    const navTextBoxList = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '20%',
        backgroundColor: tab=='list' ? 'rgba(255, 255, 255, .4)' : 'none',
        cursor: 'pointer'
    }
    const logoutButton = useMemo(() => {  
        return { 
            backgroundColor: red,
            fontSize: '2.2vh',
            fontFamily: 'ApercuProMedium',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60%',
            height: '6%',
            borderRadius: '5px',
            marginTop: '90%',
            visibility: isSettingsClicked ? 'visible' : 'hidden',
            minHeight: isSettingsClicked ? '6%' : 0,
            overflow: 'hidden',
            transition: `opacity 1s cubic-bezier(0.42, 0, 0.58, 1), 
                        maxHeight 1s, 
                        ${isSettingsClicked ? 'visibility 0s' : 'visibility 0s 1s'}`,
            opacity: isSettingsClicked ? 1 : 0,
            cursor: 'pointer'
        }
    
    }, [isSettingsClicked])
    const CirclePFP = useMemo(() => { 
        return { 
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '100%',
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: '1.5px 1.5px 1.5px 1.5px',
            backgroundColor: isPicClicked ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
            opacity: 1,
            borderColor: white,
            transform: isPicClicked ? 'scale(1.1) rotateY(180deg)' : 'scale(1)',
            transition: 'all 0.5s ease',
            width: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' :  window.innerWidth >= 1366 ?  '55px': window.innerWidth >= 1200 ? '45px' : '40px',
            height: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' : window.innerWidth >= 1366 ? '55px': window.innerWidth >= 1200 ? '45px' : '40px',
        }
    }, [isPicClicked])
   
 
    const circleSettings = useMemo(() => { 
        return {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '100%',
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: '1.5px 1.5px 1.5px 1.5px',
            backgroundColor: isSettingsClicked ? 'rgba(255, 255, 255, .4)' : 'transparent',
            borderColor: isSettingsClicked ? 'rgba(255, 255, 255, .4)' : white,
            width: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' :  window.innerWidth >= 1366 ?  '55px': window.innerWidth >= 1200 ? '45px' : '40px',
            height: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' : window.innerWidth >= 1366 ? '55px': window.innerWidth >= 1200 ? '45px' : '40px', 
            cursor: 'pointer'
        }
    }, [isSettingsClicked])
    const circleNotifications = {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '100%',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: '1.5px 1.5px 1.5px 1.5px',
        backgroundColor: tab=='notifications' ? 'rgba(255, 255, 255, .4)' : 'none',
        borderColor: tab=='notifications' ? 'rgba(255, 255, 255, .4)' : white,
        width: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' :  window.innerWidth >= 1366 ?  '55px': window.innerWidth >= 1200 ? '45px' : '40px',
        height: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' : window.innerWidth >= 1366 ? '55px': window.innerWidth >= 1200 ? '45px' : '40px',
    }
    
    const displayLogout = () => {
        setSettingsClicked(!isSettingsClicked)
        if (logoutRef.current.style.display === 'flex') {
          logoutRef.current.style.display = 'none';
        } else {
          logoutRef.current.style.display = 'flex';
        }
    }
    const logout = async () => {
        try { 
            await logoutUser()
            sessionStorage.clear();
            navigate('/Artist')
        } catch (err) {
            console.log('user could not be logged out')
        }
    }

    const goTo = (whereTo) => {

        if (whereTo == 'metrics' && tab != 'metrics') {
            setPicClicked(false)
            navigate('/Metrics', {state: {uid: userUID, pfp: pfpSrc}})
        }
        else if (whereTo == 'home' && tab!= 'home') {
            setPicClicked(false)
            navigate('/Home', {state: {uid: userUID, pfp: pfpSrc}})
        }
        else if (whereTo == 'list' && tab != 'list') {
            setPicClicked(false)
            navigate('/CreateListing', {state: {uid: userUID, pfp: pfpSrc}})
        }
        else if (whereTo == 'settings' && tab != 'settings') {
            navigate('/Settings', {state: {uid: userUID, pfp: pfpSrc}})
        }
        else if (whereTo == 'notifications' && tab != 'notifications') {
            //todo: Add notifications page...
        }
    }
    useEffect(() => {
        if (location.pathname === '/Settings') {
            setPicClicked(true);
        }
    }, [location]);
    return( 

        <div style = {{width: '20%', height: '100vh', margin: 0, overflow: 'hidden', position: 'fixed'}}>

            {disabled===true ? 
                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: accentColor, opacity: 0.4, margin: 0}}>

                    <img src = {logoDisabled} style = {{width: '5vw', height: '7.7vh', marginTop: '10%'}} />

                    <div style = {topNavDiv}>
                        <div style = {disabledCircle}>
                            <img src = {userDisabled} style = {UserIcon}/>
                        </div> 
                        <div style = {disabledCircle}>
                            <img src = {bellDisabled} style = {BellIcon}/>
                        </div> 
                        <div style = {disabledCircle}>
                            <img src = {settingsDisabled}  style = {SettingsIcon}/>
                        </div> 
                    </div> 

                    <div style = {bottomNavBox}>
                        <div style = {navTextBox}>
                            <img src = {homeDisabled} style = {navIcon} />
                            <h1 style = {headerTextDisabled}>Home</h1>
                        </div>
                        <div style = {navTextBox}>
                            <img src = {listDisabled} style = {navIcon}  />
                            <h1 style = {headerTextDisabled}>List</h1>
                        </div>
                        <div style = {navTextBox}>
                            <img src = {metricsDisabled} style = {navIcon}  />
                            <h1 style = {headerTextDisabled}>Metrics</h1>
                        </div>
                    </div>

                </div> 
                :

                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: accentColor, margin: 0}}>

                    <img src = {logoWhite} style = {{width: '5vw', height: '7.7vh', marginTop: '10%'}} />
                    <div style = {topNavDiv}>
                       
                        <img src = {pfpSrc} style = {CirclePFP} onClick = {() => goTo('settings')} />
                        
                        <div style = {circleNotifications}>
                            <img src = {bell} style = {BellIcon}/>
                        </div> 
                        <div style = {circleSettings} onClick = {() => displayLogout()} >
                            <img src = {settings} style = {SettingsIcon}/>
                        </div> 

                    </div> 

                    <div style = {bottomNavBox}>
                        <div style = {navTextBoxHome} onClick= {() => goTo('home')}>
                            <img src = {home} style = {navIcon}  />
                            <h1 style = {headerText}>Home</h1>
                        </div>
                        <div style = {navTextBoxList} onClick= {() => goTo('list')}>
                            <img src = {list} style = {navIcon}  />
                            <h1 style = {headerText}>List</h1>
                        </div>
                        <div style = {navTextBoxMetrics} onClick= {() => goTo('metrics')}>
                            <img src = {metrics} style = {navIcon}  />
                            <h1 style = {headerText}>Metrics</h1>
                        </div>
                    </div>

                    <div style = {logoutButton} ref = {logoutRef} onClick = {logout}> Logout </div>
                </div>
            }
        </div>
    )
}



const headerTextDisabled = { 
    color: disabledAccent,
    fontFamily: 'ApercuProMedium',
    fontSize: '2vh',
    marginLeft: '5%',
}
const headerText = { 
    color: white,
    fontFamily: 'ApercuProMedium',
    fontSize: '2vh',
    marginLeft: '5%',
}
const topNavDiv = { 
    width: '85%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '15%',
    alignItems: 'center',
    marginBottom: 0,
}
const bottomNavBox = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '80%',
    height: '30%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5%'
}

const UserIcon = { 
    width: '35%',
    height: '43%',
   
}
const SettingsIcon = { 
    width: '40%',
    height: '40%',
}
const BellIcon = {
    width: '40%',
    height: '45%',
}
const disabledCircle = { 
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '100%',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: '1.5px 1.5px 1.5px 1.5px',
    borderColor: disabledAccent,
    width: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' :  window.innerWidth >= 1366 ?  '55px': window.innerWidth >= 1200 ? '45px' : '40px',
    height: window.innerWidth >= 1920 ? '70px' :  window.innerWidth >= 1440 ? '60px' : window.innerWidth >= 1366 ? '55px': window.innerWidth >= 1200 ? '45px' : '40px',
}



const navIcon = {
    width: window.innerWidth >= 1920 ? '45px' :  window.innerWidth >= 1440 ? '40px' :  window.innerWidth >= 1366 ?  '35px': window.innerWidth >= 1200 ? '30px' : '25px',
    height: window.innerWidth >= 1920 ? '45px' :  window.innerWidth >= 1440 ? '40px' : window.innerWidth >= 1366 ? '35px': window.innerWidth >= 1200 ? '30px' : '25px',
    paddingLeft: '5%'
}

const navTextBox = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    
}


export default StickyNav;