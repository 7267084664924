import React, { useRef } from 'react'
import { useEffect, useState , useMemo} from 'react';
import { useNavigate } from 'react-router';
import { useInView } from 'react-intersection-observer';
import CustomButton from '../components/CustomButton';
import SpecialButton from '../components/SpecialButton';
import logoGreen from '../assets/logoGreen.png'
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
import placeholder from '../assets/placeholder.png'
import thumbsUp from '../assets/thumbs-up.png'
import trendingUp from '../assets/trendingUp.png'
import book from '../assets/book.png'
import person from '../assets/person.png'
import track from '../assets/track.png'
import pencil from '../assets/pencil.png'
import MockInvestmentPlatform from '../assets/MockupFinal.png'
import money from '../assets/money.png'
import share from '../assets/share.png'
import Footer from '../components/Footer';
import {auth} from "../database/Firebase"
import {signOut} from "firebase/auth"
import {getUser} from '../database/UserFunctions';
import logoBlack from '../assets/logoBlack.png'
import { container } from '../styles/Globals';
import artistFlicOne from '../assets/artistFlic1.jpeg'
import artistFlicTwo from '../assets/artistFlic2.jpeg'
import artistFlicThree from '../assets/artistFlic3.jpeg'
import {divToHide, greenBackgroundImage, bigLandingHeaderText, artGalleryPlaceholder, tagline, topButtonFlexer, missionHeaderText, missionSubText, subMissionHeader, subBlockFlex, missionCards, missionText, mobileFlex, whyUnveil, accentUnveil, whySubText, mobileMockup, headerTextSubBlock, subTextBlock, artistCards, subArtistHeader, artistText, notiContainer, greenBackground, headerText, subHeaderText, subHeaderTextR, paragraph, paragraphR, textButton} from '../styles/LandingStyles'
import { readyToGetStarted, subTextArtistAction, artistActionButtonFlex } from '../styles/LandingStyles';
import {artistImageFlexer, artistImageOneBox, artistImageTwoBox, artistImageThreeBox, artistImageContainer, artistImage, artistDescriptor, artistTag, artistLocation, unveilTag} from '../styles/ArtistStyles'
import { greenBackgroundImageMobile, mobileHeaderText, mobileMockupMobile, subMissionHeaderMobile,  missionCardsMobile,  subBlockFlexMobile, missionCardsMobileS, missionCardsMobileL, missionCardsMobileR, UnveilMobile, accentUnveilMobile, salutBackground, betaFlex, joinUsBetaText } from '../styles/LandingStylesMobile';
import {header} from '../styles/Globals'
import {CSSTransition} from 'react-transition-group';
import '../styles/SalutAnim.css'


export default function Landing() {

  //^ CONSTANTS:

  const navigate = useNavigate();
  const [acceptedNoti, setAcceptedNoti] = useState(false)
  const [rejectedNoti, setRejectedNoti] = useState(false)
  const [userUID, setUserUID] = useState('')
  const overlayRef = useRef()
  const transitionRef = useRef()
  const { ref: youngSalutRef, inView: isSalutVisible } = useInView();

  //* Div we will use to create overlay popup effect
  // const overlayDiv = {
  //   display: (acceptedNoti===true || rejectedNoti ===true) ? 'flex' : 'none',
  //   width: window.screen.width,
  //   height: window.screen.height,
  //   zIndex: 2,
  //   backgroundColor: 'rgb(53, 53, 53, .68)',
  //   position: 'fixed',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   margin: 0,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // }

  const salutBackground = useMemo(()=> {
      return { 
        width: '100%',
        marginTop: '15%', 
        minHeight: '40vh',
        marginBottom: 0,
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${artistFlicTwo})`,
        backgroundColor: isSalutVisible ?  'rgba(0, 0, 0, 0.9)' : 'none'
       
       
      }
  }, [isSalutVisible])

  //& Function to reach out to our email with questions / concerns 
  const contactUs = () => { 
    window.location.href = "mailto:ops@unveil.group"
  }
  //& Function to route the user to the correct location: once they select "artist console"
  //& if the user's credentials are remembered route them directly the home screen
  //& Otherwise go to login and we will decide from there 
  async function artistConsolePath() {

    auth.onAuthStateChanged(async user => {
      
      //! There was a logged in user
      if (user && localStorage.getItem('expirationDate')) {

        const rememberStorage = JSON.parse(localStorage.getItem('expirationDate'))
        //considered authenticated so store true in session storage 
        sessionStorage.setItem('authenticated', 'true')
        const currentDay = new Date()
        const currentTime = currentDay.getTime()

        //! AND There credentials have not expired 
        if (rememberStorage.expirationTime > currentTime) { 

          let loggedInUser = await getUser(user.uid) 
          switch (loggedInUser.artistApplicationStatus) {

            case undefined:
                navigate("/Apply", {state: {uid: user.uid}})
                break;
          
            case "pending":
              navigate("/ApplicationPending")
              
            case "rejected":
              navigate("/ApplicationRejected")
              break;
            
            case "accepted":
              navigate("/ApplicationAccepted", {state: {uid: user.uid}})
              break;

            case "onboarded":
              navigate("/Home", {state: {uid: user.uid}})
              break;

            default:
              break;
          }
        } else { 
          await signOut(auth)
          localStorage.removeItem('expirationDate')
          console.log('credentials expired, log in again ⚙️' )
          navigate("/WelcomeBackLogin")
        }
      }
      //* In this case either rememberMe was not selected previously, or rememberMe was selected but firebase has expired, so lets route them back to login
      else { 
        navigate('/WelcomeBackLogin')
      }
    })
  }

  //& get User call to check app status and render components accordingly 
  async function checkApplicationNotification() {
    auth.onAuthStateChanged(async user => { 

      if (user) {
        let loggedInUser = await getUser(user.uid)
        //todo: get rohan to add rejected and "seen" api call 
        if (loggedInUser.artistApplicationStatus == 'rejected') {
          console.log('user app was rejected')
          setRejectedNoti(true)
        }
        else if (loggedInUser.artistApplicationStatus == 'accepted') {
          console.log('user app was accepted')
          setAcceptedNoti(true)
          setUserUID(user.uid)
         
        } 
      }
    })
  }

  //& Close either the rejected or accepted popup window, make final API call if rejected
  function closePopUp(type) {
    overlayRef.current.style.display = 'none'
    if (type == 'rejected') {
      //todo: make API Call to be Rejected and Seen...
    }
    else { 
      overlayRef.current.style.display = 'none' //* We want to keep reminding them until they complete their profile and onboard...
    }
  }

  //& ONLY ON FIRST RENDER, check if user has been recently accepted or rejected from Unveil as an artist and therefore needs to see a notification informing them so 
useEffect(() => {
      checkApplicationNotification()
  }, []);

  return (

    <div style = {container}> 
{/* 
      <div style = {overlayDiv} ref = {overlayRef}> 

        {acceptedNoti === true ? 
        
          <div style = {notiContainer}>
            <div style={greenBackground}>
                <img src = {logoBlack} style = {{width: 128, height: 128}} />
            </div>
            <div>
                <h1 style = {headerText}>Congratulations!</h1>
                <h2 style = {subHeaderText}>We are delighted to invite you to be an Unveil artist! </h2>
                <p style = {paragraph}>Before you can list specific works on our platform, you will need to fill out your artist profile. Your profile is public to art investors, so consider this a great way to tell your story and convey the deeper themes surrounding your art to your audience.</p>
                <div style = {{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '50%', marginLeft: '10%', alignItems: 'center'}}>
                  <SpecialButton type={'dark'} text={"Create Artist Profile"} callbackfunction = {() => navigate('/CompleteProfile', {state: {uid: userUID}})} height = {52} width = {232} mBottom={'2.5%'} mTop={'5%'}> </SpecialButton>
                  <p style = {textButton} onClick = {() => closePopUp('accepted')}> Return to Home</p>
                </div>
            </div> 
          </div>

          :
          <div style = {notiContainer}>
            <div style={greenBackground}>
                <img src = {logoBlack} style = {{width: 128, height: 128}} />
            </div>
            <div>
                <h1 style = {headerText}>Thank you for your interest.</h1>
                <h2 style = {subHeaderTextR}>Unfortunately we are unable to accept you as an Unveil artist at this time. </h2>
                <p style = {paragraphR}>We hope that you will consider applying again in the future. If you have questions or concerns please contact us using the 'contact us' button. Thank you again for sharing your work with us</p>
                <SpecialButton type={'outline'} text={"Return to Home"} callbackfunction = {() => closePopUp('rejected')} height = {52} width = {232} mBottom={'2.5%'} mTop={'5%'} mLeft={'10%'}> </SpecialButton>
               
            </div> 
          </div>

        }

      </div> */}
    {window.innerWidth > 1000 ?  
      <div style = {divToHide}> 
     
      <div style = {header}>
          <img src = {logoGreen} style = {{width: '5%', height: '5%'}} />
          <CustomButton type={'dark'} text={"Artist Console"} callbackfunction = {() => artistConsolePath()} height = {'5vh'} width = {'12vw'}> </CustomButton>
      </div>  

      <div style = {greenBackgroundImage}> 
        <h1 style = {bigLandingHeaderText}> Empowering Artistic <br/> Appreciation & <br/> Financial <br/> Independence</h1>
        <img style = {mobileMockup} src = {MockInvestmentPlatform}/>
      
      </div>
      
      <p style = {tagline}>Unveil is a pioneering online platform that transforms the world of fine art into an accessible marketplace. 
        Our platform bridges the gap between art lovers and investment enthusiasts by offering a unique 
        opportunity to own fractional shares of masterpieces from renowned artists. </p>

      <div style = {topButtonFlexer}> 
        <CustomButton type={'dark'} text={"For Artists"} callbackfunction = {() => navigate('/ArtistSignup')} height = {'5.5vh'} width = {'12vw'} windowWidth={window.innerWidth}> </CustomButton>
        <CustomButton type={'outline'} text={"Contact Us"} callbackfunction = {() => contactUs()} height = {'5.5vh'} width = {'12vw'} windowWidth={window.innerWidth} > </CustomButton>
      </div>

      <h1 style = {headerTextSubBlock}>Our Mission</h1>
      <p style = {subTextBlock}> Unveil's mission is to democratize access to the fine art market, foster financial growth through art investments, enhance art <br /> 
      education and appreciation, and ensure the preservation of artistic masterpieces for future generations.</p>

      <div style = {subBlockFlex}>

          <div style = {missionCards}>   
            <img src = {person} style = {{height: '15%', width: '15%'}} />
            <h1 style = {subMissionHeader}> Democratize Art Investment </h1>
            <p style = {missionText}>We are making art investment a financial opportunity for all, breaking down exlusive barriers</p>
          </div>

          <div  style = {missionCards}>
            <img src = {thumbsUp} style = {{height: '15%', width: '15%'}} />
            <h1 style = {subMissionHeader}> Support Emerging Artists </h1>
            <p style = {missionText}>We are committed to uplifting talented and underrepresented artists and promoting their innovative works.</p>
          </div>

          <div  style = {missionCards}>
            <img src = {trendingUp} style = {{height: '15%', width: '15%'}} />
            <h1 style = {subMissionHeader}> Financial Empowerment </h1>
            <p style = {missionText}>Our platform seeks to create financial freedom for both artists and investors, utilizing a secondary market.</p>
          </div>

          <div  style = {missionCards}>
            <img src = {book} style = {{height: '15%', width: '15%'}} />
            <h1 style = {subMissionHeader}> Art Education and Appreciation</h1>
            <p style = {missionText}>We envision a community that appreciates fine art and recognizes the art market's historical inequalities.</p>
          </div>

      </div>

      <div style = {mobileFlex}>
        <div style = {{margin: 0, width: '40%'}}>
          <h1 style = {whyUnveil}>Why <span style = {accentUnveil}>Unveil</span>?</h1>
          <p style = {whySubText}>There is no fractional art investment platform like Unveil in the current market.  Through Unveil, artists can list their pieces, and users can purchase fractional stakes in those pieces. Once the piece appreciates and is acquired by a third party, the users profit respective to their stake in the piece. By essentially selling "shares" of a piece to multiple fans, we hope to democratize the creative space for artists and fans, alike.</p>
        </div>
        <div style = {artistImageFlexer}>

          <div style = {artistImageContainer}>
            <img src = {artistFlicTwo} style = {artistImage} />
          </div>
          <div style = {artistDescriptor} > 
            <p style = {artistTag}> Young Salut, <span style = {artistLocation}>Rochester, NY</span></p>
            <p style = {unveilTag} >Unveil Artist</p>
          </div>
      </div>
      </div>
      <h1 style = {headerTextSubBlock}>How It Works For Artists</h1>
      <p style = {subTextBlock}> At Unveil, we champion emerging artists. Our goal is to amplify your voice, expand your reach, and ensure you benefit both <br/>
      artistically and financially. We're committed to elevating your art and enhancing your success..</p>

      <div style = {subBlockFlex}>

          <div style = {artistCards}>   
            <img src = {pencil} style = {{height: '4.5vh', width: '2.8vw', paddingTop: '5%', marginLeft: '5%'}} />
            <h1 style = {subArtistHeader}> Become an Unveil Artist </h1>
            <p style = {artistText}> Create an account and become <br />an Unveil artist.</p>
          </div>

          <div  style = {artistCards}>
            <img src = {share} style = {{height: '4.5vh', width: '2vw', paddingTop: '5%', marginLeft: '5%'}} />
            <h1 style = {subArtistHeader}> List Your Art Work</h1>
            <p style = {artistText}>Upload your artwork to start an investment campaign on our platform.</p>
          </div>

          <div  style = {artistCards}>
            <img src = {track} style = {{height: '4.5vh', width: '2.5vw', paddingTop: '5%', marginLeft: '5%'}}/>
            <h1 style = {subArtistHeader}> Track Your Art Work </h1>
            <p style = {artistText}>Track your art's perfomance and popularity on Unveil.</p>
          </div>

          <div  style = {artistCards}>
            <img src = {money} style = {{height: '4.5vh', width: '1.6vw', paddingTop: '5%',  marginLeft: '5%'}} />
            <h1 style = {subArtistHeader}> Receive Payments</h1>
            <p style = {artistText}>Receive shares sold of your artwork and profit from its secondary resale.</p>
          </div>

      </div>

      <h1 style = {readyToGetStarted}>Ready to Get Started?</h1>
      <p style = {subTextArtistAction}>Apply to start your Unveil artist journey and build your community. You can also contact us for questions.</p>
      <div style = {artistActionButtonFlex}> 

        <CustomButton type={'dark'} text={"Get Started"} callbackfunction = {() => navigate('/ArtistSignup')} height = {'5.5vh'} width = {'12vw'} windowWidth={window.innerWidth}> </CustomButton>
        <CustomButton type={'outline'} text={"Contact Us"} callbackfunction = {() => contactUs()} height = {'5.5vh'} width = {'12vw'} windowWidth={window.innerWidth}> </CustomButton>
      </div>

      <Footer></Footer>
      
    </div> :
    <div style = {divToHide}>
      <div style = {header}>
          <img src = {logoGreen} style = {{width: '15%', height: '15%'}} />
          <CustomButton type={'dark'} text={"Beta Access"} callbackfunction = {() => window.location.href = "https://bit.ly/unveilartbetageneral"} height = {'4vh'} width = {'30vw'}> </CustomButton>
      </div>  
      <div style = {greenBackgroundImageMobile}> 
        <img style = {mobileMockupMobile} src = {MockInvestmentPlatform}/>
      </div>
      {/* <h1 style = {mobileHeaderText}>Empowering Artist Appreciation and Financial Independence</h1> */}
      <h1 style = {UnveilMobile}>Un.<span style = {accentUnveilMobile}>veil</span></h1>
      <div style = {subBlockFlex}>

        <div style = {subBlockFlexMobile}>
          <div style = {missionCardsMobileL}>   
            <img src = {person} style = {{height: '35%', width: '35%'}} />
            <h1 style = {subMissionHeaderMobile}> Democratizing  Art Investment </h1>
            {/* <p style = {missionTextMobile}>Making art investment simple and open to all</p> */}
          </div>

          <div  style = {missionCardsMobileS}>
            <img src = {trendingUp} style = {{height: '35%', width: '35%'}} />
            <h1 style = {subMissionHeaderMobile}> Support Emerging Artists</h1>
            {/* <p style = {missionTextMobile}>We are committed to uplifting talented and underrepresented artists and promoting their innovative works.</p> */}
          </div>

          <div  style = {missionCardsMobileR}>
            <img src = {book} style = {{height: '35%', width: '35%'}} />
            <h1 style = {subMissionHeaderMobile}> Art Appreciation and Growth</h1>
            {/* <p style = {missionTextMobile}>We envision a community that appreciates fine art and recognizes the art market's historical inequalities.</p> */}
          </div>
        </div>
      
      </div>

      {/* <h1 style = {joinUsBetaText}>Unveil is breaking down barriers and redefining what it means to invest in art. Join us for our debut piece listing, courtesty of Rochester artist Young Salut</h1> */}
      <div style = {salutBackground} ref = {youngSalutRef}>
        <CSSTransition 
            in = {isSalutVisible}
            timeout={12000}
            appear={true}
            classNames = "popup"
            nodeRef = {transitionRef}
            style = {{width: '85%', minHeight: '25vh', backgroundColor: white, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '5px'}}
            > 
              <div ref = {transitionRef} style = {{backgroundColor: white, width: '100%', minHeight: '100%',  display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                
                <p style = {joinUsBetaText}>Unveil is redefining what it means to invest in art. Join us for our debut piece listing, courtesty of Rochester artist: <span style = {{color: primary, fontFamily: 'ApercuProBoldItalic',fontSize: 15 }}>Young Salut</span></p>
                <CustomButton type={'outline'} text={"Join Waitlist"} callbackfunction = {() => window.location.href = "https://bit.ly/unveilartbetageneral"} height = {'5vh'} width = {'35%'}> </CustomButton>
              </div>
        </CSSTransition>  
      </div>
     
      <Footer></Footer>

    </div>
      
    }
      

    </div>
     
  )
}