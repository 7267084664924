import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'


export const subText = {
    fontFamily: 'ApercuProRegular', 
    fontSize: window.innerWidth > 1300 ? 18 : 16, 
    color: black, 
    textAlign: 'center',
    width: window.innerWidth > 1300 ? '75%' : '85%', 
    lineHeight: '140%', 
    marginTop: '-2%'
}
export const forgotPasswordContainer = { 
    backgroundColor: white,
    width: '40vw',
    height: '55vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}
export const inputFlexer = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '70%',
    height: '15%',
    marginTop: '5%',
}
export const labelTextOne = {
    color: black,
    fontFamily: 'ApercuProRegular',
    padding: 0,
    fontSize: 14,
    marginBottom: 0
  
}
export const backToLoginButton = { 
    fontFamily: 'ApercuProBold',
    fontSize: window.innerWidth > 1300 ? 18 : 16, 
    color: primary,
    textAlign: 'center',
    display: 'inline',
    marginTop: '3.5%',
    cursor: 'pointer'
}
  