
import { useNavigate, useLocation } from 'react-router-dom';
// import artistAppBackground from '../assets/backgroundDesign.svg'
import CustomButton from '../components/CustomButton';
import logoBlack from '../assets/logoBlack.png'
import SpecialButton from '../components/SpecialButton';
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
import {greenContainer, header, container, greenBackground} from '../styles/Globals'
import { notiContainer, greenSide, headerTextApp, subHeaderTextApp, paragraphApp, textFlexer, subHeaderTextReject} from '../styles/ApplicationStatusStyles';

export default function ApplicationRejected() {

  //^ CONSTANTS.
  const navigate = useNavigate();
  const location = useLocation();


  //& Have contact us button go to email 
  const contactUs = () => { 
      window.location.href = "mailto:ops@unveil.group"
  }

  //todo Add API Call if artist gets to this screen to move status to "rejected and seen" or whatever rohan comes up with 
  
  return (

      <div style = {container}>
       
          <div style = {header}>
              <img src = {logoBlack} style = {{width: '5%', height: '5%'}} />
              <CustomButton type={'dark'} text={"Contact Us"} callbackfunction = {() => contactUs()} height = {'5vh'} width = {'12vw'}> </CustomButton>
          </div> 
          
          <div style = {greenBackground}>
            <div style = {notiContainer}>
              <div style={greenSide}>
                <img src = {logoBlack} style = {{width: '52%', height: '25%'}} />
              </div>
              <div style = {textFlexer}>
                  <h1 style = {headerTextApp}>Thank you for your interest.</h1>
                  <h2 style = {subHeaderTextReject}>Unfortunately we are unable to accept your application at this time </h2>
                  <p style = {paragraphApp}>We hope that you will consider applying again in the future. If you have questions or concerns please contact us using the 'contact us' button. Thank you again for sharing your work with us and good luck in all future endeavours.</p>
                  <CustomButton type={'outline'} text={"Return to Home"} callbackfunction = {() => navigate('/Artist')} height = {'11%'} width = {'24%'}  mTop = {'2%'} mLeft={'10%'}> </CustomButton>
                
              </div> 
            </div>
        </div>
    </div>
  )
}
