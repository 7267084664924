import React, { useMemo } from 'react'
import CustomButton, { AppButton } from '../components/CustomButton';
import { useState, useRef } from 'react';
import { emailLogin, uploadFile, getAuthToken} from '../database/AuthFunctions';
import { getUser} from '../database/UserFunctions';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import StickyNav from '../components/StickyNav'
import bigUser from '../assets/profileCircle.svg'
import {background, primary, secondary, orange, white, black, green, red,  accentColor, blurredWhite, lightGray, altGray, disabledAccent, darkGray} from '../styles/Colors'
import uploadIt from '../assets/uploadSuperGreen.svg'
import changeIcon from '../assets/changeIcon.svg'
import { containerNavPresent, smallWhiteContainerNavPresent, headerTextNavPresent, headerSubNavPresent } from '../styles/Globals';
import { inputsContainer, imageContainer, bigUserIcon,  labelText, textInputsContainer,  profilePicture, inputContainer, profileInputContainer, imageEmptyContainer, blankProfile, uploadPhotoButton, mockUpContainer, mockupProfile} from '../styles/CompleteProfileStyles';
import profileMockup from '../assets/ArtistProfileMockup.png'

export default function CompleteProfile() {

  //^CONSTANTS.
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const [profilepic, setProfilePic] = useState('')
  const [artbio, setArtBio] = useState('')
  const [errorpfp, setErrorPfp] = useState(false)
  const [errorArtBio, setErrorArtBio] = useState(false)
  const artBioRef = useRef()
  const profileRef = useRef()


  //& Function that allows user to view how their profile photo looks before submission 
  const previewProfile = (event) => { 
    setProfilePic(event.target.files[0])

    if (errorpfp && profilepic != '') {
      artBioRef.current.style.borderWidth ='1.5px 1.5px 1.5px 1.5px'
      artBioRef.current.style.borderColor = lightGray
      artBioRef.current.style.borderStyle = 'solid'
      setErrorPfp(false)
    }
    
  };
  

  //& Function to progress user and complete their profile, or handle errors
  async function sendProfileUpdate() {
    
    setIsLoading(true)

    const finalProfilePicture = await uploadFile(profilepic, 'profilePictures')

    var headers = new Headers();
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", await getAuthToken());
    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        uid: location.state.uid,
        bio: artbio,
        pfp: finalProfilePicture

      }),
      redirect: 'follow'
    }
    
    let res = await fetch("https://us-central1-artisynapp.cloudfunctions.net/artists/onboard", requestOptions)

    if(res.status == 200) {
      let user = await getUser(location.state.uid)
      console.log("Success")
      setIsLoading(false)
      navigate("/Home", {state: {uid: location.state.uid, pfp: user.pfp}})

    } else {
      console.log("error, request failed", + res.err)
      if (artbio == '') {
        setErrorArtBio(true)
        artBioRef.current.style.borderWidth = '2px 2px 2px 2px'
        artBioRef.current.style.borderColor = red
        artBioRef.current.style.borderStyle = 'solid'
      }
      if (profilepic == '') {
        setErrorPfp(true)
        profileRef.current.style.borderWidth = '1.5px 1.5px 1.5px 1.5px'
        profileRef.current.style.borderColor = red
        profileRef.current.style.borderStyle = 'solid'
      }
      
      setIsLoading(false)
      return
    }

  }


  //& Revert border color of any error box upon reclick 
  const revertBox = (ref) => { 
  if (errorArtBio) {
    if (ref = 'bio') {
      artBioRef.current.style.borderColor = lightGray
      artBioRef.current.style.borderWidth = '0px 0px 1px 0px'
      setErrorArtBio(false)
    }
  }
  }
  
 const textBoxOutlineBio = useMemo(()=> {
  return { 
    width: '100%',
    minWidth: '100%',
    height: '60%',
    minHeight: '60%',
    outline: 'none',
    borderWidth: errorArtBio ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
    backgroundColor: 'rgba(120, 153, 137, .1)',
    borderColor: errorArtBio ? red : lightGray,
    backgroundColor: 'rgba(120, 153, 137, .1)',
    marginTop: '1%',
    fontFamily:'ApercuProRegular',
    fontSize: '2vh',
    textIndent: 10,
    color: black,
    paddingTop: 15,
    lineHeight: '140%'
  }
}, [errorArtBio])
  

  return (
    <div style = {containerNavPresent}> 



      <StickyNav tab = 'none' pfpSrc = 'none' disabled = {true}> </StickyNav>
      <div style = {smallWhiteContainerNavPresent}>
        <h1 style = {headerTextNavPresent}>Artist Profile</h1>
        <p style = {headerSubNavPresent}>Your artist profile is an extension of the original profile you created on Unveil's mobile app. This information will be displayed for your audience and potential investors to read and learn about your story.</p>


        <div style = {inputContainer}> 

          <div style = {inputsContainer}> 

            <div style = {profileInputContainer}>
                {profilepic == '' ?  

                  <div style = {imageEmptyContainer}> 
                    <div style = {blankProfile()} ref = {profileRef}>
                    
                        <input id = "profileInputOne" type = "file"  onChange={previewProfile} hidden accept='image/*' />    
                    
                        <label htmlFor="profileInputOne" style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', borderRadius: '100%'}}>  
                          <img src={bigUser} style = {bigUserIcon}/>
                        </label>    
                        
                    </div>
                    <label htmlFor="profileInputOne" style = {uploadPhotoButton}>Upload Photo
                      <img src = {uploadIt} style = {{width: '2.5vw', height: '2.5vh', display: 'inline-flex'}}/>
                    </label>  
                  </div>

                    :

                    <div style = {imageEmptyContainer}> 
                      <div style = {blankProfile()}>

                        <input id = "profileInputTwo" type = "file"  onChange={previewProfile} hidden accept='image/*' />    

                        <label htmlFor="profileInputTwo" style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', borderRadius: '100%'}}> 
                          <img style= {profilePicture} src = {URL.createObjectURL(profilepic)} alt="Profile Picture" />
                        </label>   
                      </div>
                      <label htmlFor="profileInputTwo" style = {uploadPhotoButton}>Change Photo
                        <img src = {uploadIt} style = {{width: '2.5vw', height: '2.5vh', display: 'inline-flex'}}/>
                      </label>  
                    </div>
                  }
              
                <label style = {labelText}>Artist Bio </label>
                <textarea type = "text" placeholder="Start typing your bio here..." value={artbio} onChange = {(event) => setArtBio(event.target.value)} ref = {artBioRef} style = {textBoxOutlineBio} onClick = {() => revertBox('bio')}></textarea>
                <CustomButton type = 'dark' text = {'Submit'} callbackfunction={ () => sendProfileUpdate()} height = {'25%'} width = {'60%'} mTop={'5%'} wide = {false} loading = {isLoading}>  </CustomButton>
          
            </div>

          </div>
          <div style = {mockUpContainer}>
                <img style = {mockupProfile} src = {profileMockup} />
          </div>

        </div>
      
      </div>

    </div>
    
  )
}



