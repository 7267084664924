import { useNavigate, Link, useResolvedPath} from "react-router-dom"
import { useEffect, useState, useRef, useMemo } from 'react';
import { emailLogin, resetPassword } from '../database/AuthFunctions';
import CustomButton from "../components/CustomButton";
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, darkGray} from '../styles/Colors'
import {title, subText} from '../styles/LoginStyles'
import {forgotPasswordContainer, labelTextOne, backToLoginButton, inputFlexer} from '../styles/ForgotPasswordStyles'
import { greenContainer } from "../styles/Globals";
export default function ForgotPassword() {

  //^ CONSTANTS: 
  const navigate = useNavigate();
  const [resetEmail, setResetEmail] = useState('')
  const emailResetBox = useRef()
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  //& Send Password reset email or render error 
  const sendResetEmail = async () => {

      setIsLoading(true)
      let res = await resetPassword(resetEmail)
      setIsLoading(false)

      if (res.success) {
          navigate('/ResetSuccess')
      } else { 
          setError(true)
          emailResetBox.current.style.borderColor = red
          emailResetBox.current.style.borderWidth = '2px 2px 2px 2px'
          emailResetBox.current.style.borderStyle = 'solid'
      }
  }

  //& Revert border color of text box 
  //todo make this fire only when there is an error 
  const revertBox = (ref) => { 
      if (!error) return
      else emailResetBox.current.style.borderColor = lightGray
      emailResetBox.current.style.borderWidth = '0px 0px 1px 0px'
  }


  const textBoxOutlineFP = useMemo(() => { 
    return { 
      width: '100%',
      height: '75%',
      outline: 'none',
      borderWidth: error ? '2px 2px 2px 2px' : '0px 0px 1px 0px',
      borderColor: error ? red : lightGray,
      borderStyle: 'solid',
      backgroundColor: 'rgba(120, 153, 137, .1)',
      marginTop: '1%',
      fontFamily:'ApercuProRegular',
      fontSize: 16,
      textIndent: 10,
      color: black,
    }
  }, [error])

 return (

    <div style = {greenContainer}>
      <div style = {forgotPasswordContainer}>
          <h1 style = {title}>Forgot your password? </h1>
          <p style = {subText}>There is nothing to worry about, we'll send you a message to help you reset your password.</p>

          <div style = {inputFlexer}> 
            <label style = {labelTextOne}>Email Address</label>
            <input type = "text" value={resetEmail} onChange = {(event) => setResetEmail(event.target.value)} placeholder='Enter your email' onClick = {() => setError(false)} style = {textBoxOutlineFP} ref = {emailResetBox}/>
          </div>
          <CustomButton type={'dark'} text={"Send Reset Link"} callbackfunction = {() => sendResetEmail()} height = {'5vh'} width = {'70%'} mTop = {'9%'} loading = {isLoading}> </CustomButton>

          <h1 onClick = {() => navigate('/WelcomeBackLogin')} style = {backToLoginButton}>Back to Login</h1>
      </div>
    </div>
  );
}

