
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
//& Artist Application Rejected or Accepted
export const ApplicationStatus = ({status}) => {

    return (
        <div style = {notiContainer}>
        {status=='artistApplicationAccepted' ?
            <div style = {textContainer}> 
                <h1 style = {notiHeader}>Artist Application Accepted</h1>
                <p style = {notiSubText}>Congratulations! Your artist application was accepted and you can now begin listing pieces as an Unveil artist</p>
            </div>
            :
            <div style = {textContainer}>
                <h1  style = {notiHeader}>Artist Application Rejected</h1>
                <p style = {notiSubText}> Unfortunately we are unable to accept you as an Unveil artist at this time. We hope that you will consider applying again in the future. </p>
            </div>

        }
        </div> 
    )
}
//& Listing Application Rejected or Accepted
export const ListingRequestStatus = ({status, listingApplication}) => {
    
    return (
        <div style = {notiContainer}>
        {status=='listingRequestAccepted' ?
            <div style = {textContainer}> 
                <h1 style = {notiHeader}>Your Listing Application Was Accepted</h1>
                <p style = {notiSubText}>Your Piece Listing Application for <span style = {pieceTitle}>{listingApplication.title}</span> was accepted and the piece will be going live to investors on the Unveil platform. You can monitor the pieces campaign right here.</p>
            </div>
            :
            <div style = {textContainer}>
                <h1 style = {notiHeader}>Your Listing Application Was Rejected</h1>
                 <p style = {notiSubText}> Unfortunately, we chose not to approve your Piece listing Application for <span style = {pieceTitle}>{listingApplication.title}</span>. We know this might be disheartening to hear, but we encourage you not to give up and submit more work in the future. </p>
            </div>

        }
        </div> 
    )
}

//& Listing Application Rejected or Accepted
export const CampaignStatus = ({status, listing}) => {
    
    return (
        <div style = {notiContainer}>
        {status=='waitlistBegun' ?
            <div style = {textContainer} > 
                <h1 style = {notiHeader}>Funding Successful: Waitlist Begun</h1>
                <p style = {notiSubText}>Your campaign for <span style = {pieceTitle}>{listing.title}</span> was fully funded! A one week waitlist period has begun for investors who missed out, stay tuned.</p>
            </div>
            :
            <div> style = {textContainer}
                <h1 style = {notiHeader}>Campaign Failure</h1>
                <p style = {notiSubText}> Your campaign for <span style = {pieceTitle}>{listing.title}</span> was not fully funded by the listed time period and therefore, you will not be receiving any profits from the campaign.</p>
            </div>

        }
        </div> 
    )
}

//& Opt in Period or Opt Out Period
export const OptPeriod = ({status, listing}) => {
    
    return (
        <div style = {notiContainer}>
        {status=='optOutBegun' ?
            <div style = {textContainer}> 
                <h1 style = {notiHeader}>Opt-Out Period Begun</h1>
                <p style = {notiSubText}>The Opt-Out period for <span style = {pieceTitle}>{listing.title}</span> has begun! You don't need to do anything else at this time.</p>
            </div>
            :
            <div style = {textContainer}>
                <h1 style = {notiHeader}>Opt-In Period Begun</h1>
                <p style = {notiSubText}>The Opt-in period for <span style = {pieceTitle}>{listing.title}</span> has begun! You don't need to do anything else at this time.</p>
            </div>

        }
        </div> 
    )
}

//& Final Campaign Completion or Failure
export const FinalCampaignStatus = ({status, listing}) => {
    
    return (
        <div style = {notiContainer}>
        {status=='campaignSuccess' ?
            <div style = {textContainer}> 
                <h1 style = {notiHeader}>Campaign Completed</h1>
                <p style = {notiSubText}>Your campaign for <span style = {pieceTitle}>{listing.title}</span> has been fully funded and investors have confirmed their stakes! has begun! You will be receiving an email for payment and piece shipping details shortly.</p>
            </div>
            :
            <div style = {textContainer}>
                <h1 style = {notiHeader}>Campaign Failure</h1>
                <p style = {notiSubText}> Unfortunately your campaign for <span style = {pieceTitle}>{listing.title}</span> was not fully funded after the Opt-in and Opt-out periods. Therefore, you will not be receiving any profits from the campaign.</p>
            </div>

        }
        </div> 
    )
}

//todo POST BETA: Fix and add details to acquisition offer notifications...
export const AcquisitonOffer = ({status, listing}) => {
    
    return (
        <div>
            <div style = {textContainer}>
                <h1 style = {notiHeader}>Acquisition Offer</h1>
                <p style = {notiSubText}> The ownership group for  <span style = {pieceTitle}>{listing.title}</span> just received an acquisition offer and is voting on the decision of whether to sell currently.</p>
            </div>
        </div> 
    )
}

export const AcquisitionOfferStatus = ({status, listing}) => {
    
    return (
        <div>
        {status=='acquisitionOfferAccepted' ?
            <div> 
                 <div style = {textContainer}>
                    <h1 style = {notiHeader}>Piece Acquired!</h1>
                    <p style = {notiSubText}> The ownership group for  <span style = {pieceTitle}>{listing.title}</span> voted to accept this acquisition offer. Check your email for next steps and how to receive your profits.</p>
                </div>
            </div>
            :
            <div>
                 <div style = {textContainer}>
                    <h1 style = {notiHeader}>Piece Acquired!</h1>
                    <p style = {notiSubText}> The ownership group for <span style = {pieceTitle}>{listing.title}</span> voted to reject this acquisition offer, but stay tuned for more.</p>
                </div>
            </div>

        }
        </div> 
    )
}

//^ STYLES (NOTIFICATIONS)
const notiContainer = { 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: 0
}
const textContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: white,
    borderColor: 'rgba(120, 153, 137, .25)',
    borderStyle: 'solid',
    borderWidth: '1px, 1px, 1px, 1px',
    width: '100%',
    height: '100%',
    borderRadius: '10px'
}

const notiHeader = { 
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '2vh', 
    color: black,
    marginLeft: '5%',
    marginTop: '5%'
}
const pieceTitle = {
    fontFamily: 'ApercuProBoldItalic',
    fontSize: '1.6vh',
    color: black,
    outline: 'none',
    lineHeight: '140%'
}
const notiSubText = { 
    fontFamily: 'ApercuProRegular',
    fontSize: '1.6vh',
    color: black,
    outline: 'none',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: 0,
    lineHeight: '130%'
}
// const notiHeaderImage = { 
//     fontFamily: 'PPNeueMontrealBold',
//     fontSize: 20,
//     color: black,
// }
// const imageTextContainer = { 
//     display: 'flex',
//     flexDirection: 'row',
//     width: '100%'
// }
// const pieceImage = {
//     width: '40px',
//     heigth: '40px',
//     minHeight: '40px',
//     minWidth: '40px',
//     borderTopLeftRadius: '10px 10px'
// }