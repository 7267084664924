import React, { useEffect, useState, useRef, useMemo} from 'react'
import { getPieces, getUser, getListingApplications } from '../database/UserFunctions';
import { useNavigate, Link, useLocation} from "react-router-dom"
import StickyNav from '../components/StickyNav';
import artPlaceholder from '../assets/artPlaceholder.svg'
import {background, primary, secondary, orange, white, black, green, red, accentColor, blurredWhite, lightGray, darkGray} from '../styles/Colors'
import {containerNavPresent, smallWhiteContainerNavPresent, headerTextNavPresent} from '../styles/Globals'
import  {contentContainer, contentOne, largeImageContainer, imgPreviewLarge, imgSelector, imageContainer, emptyImageContainer, imgPreviewSmall, artPlaceholderSmall, genreScroll, genreButtonBoxSmall, pieceDescription, imageContainerFirst, subHeaderFunding, quotes} from '../styles/PieceSelectedStyles'
import { contentTwoMetrics, pieceFactBox, pieceFact, subHeader } from '../styles/PieceSelectedStyles';
import { contentThree, graphTextAligner, axisMap, yAxisStyle, timeRemaining, outerAxiis } from '../styles/PieceSelectedStyles';
export default function PieceSelected() {

    //^CONSTANTS, DATA HELPERS
    const navigate = useNavigate();
    const location = useLocation();
    

    //^PIECE DATA
    const [amountReserved, setAmountReserved] = useState(0)
    const [investorCount, setInvestorCount] = useState(0)
    const [axisNumbers, setAxisNumbers] = useState([])
    const [percentageReserved, setPercentageReserved] = useState(0)
    const imgPreviewSelectedRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()])
    const [imagePreviewIndex, setImagePreviewIndex] = useState(0)
  


    //^RESERVED AND REMAINING FOR GRAPH 
    const reserved = useMemo(() => {
        return { 
            backgroundColor: primary,
            width: '50%',
            color: white,
            fontFamily: 'PPNeueMontrealBold',
            fontSize: '1.8vh',
            lineHeight: '140%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            margin: 0,
            minHeight: "" + percentageReserved + "%"
        }
        
    }, [percentageReserved])

    const remaining = useMemo(() => { 
        return  { 
            backgroundColor: 'rgba(120, 153, 137, .2)',
            width: '50%',
            margin: 0,
            minHeight: "" + (100 - percentageReserved) + "%"
        }
    }, [percentageReserved])

    //& Do investment calculation to determine amount of investors and investment amount 
    const calculateInvestmentValues = () => {
        let reservedAmount = 0;
        let numberOfInvestors = 0;
        let investorIDs = []
        for (let i = 0; i < location.state.piece.reservations.length; i++) {
            if (!investorIDs.includes(location.state.piece.reservations[i].uid)) numberOfInvestors = numberOfInvestors + 1;
            
            reservedAmount = reservedAmount + location.state.piece.reservations[i].amount;
        }
        
        let price = location.state.piece.price
        setAmountReserved(reservedAmount)
        setInvestorCount(numberOfInvestors)
        setPercentageReserved((reservedAmount / price) * 100)
        let tickValue = price / reservedAmount
        let axisArray = [price, Math.round((price - (tickValue * 1))), Math.round((price - (tickValue * 2))), Math.round((price - (tickValue * 3))), Math.round((price - (tickValue * 4))), Math.round((price - (tickValue * 5)))]
        setAxisNumbers(axisArray)
      
  
        
    }

    //& Select Image Preview
    const selectImagePreview = (newPreviewIndex) => {

        if (imagePreviewIndex != newPreviewIndex) { //* different image than what we are already previewing
            //! Reset border of previous preview and remove "remove image button"
            imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.border = 'none'

            //! New border for new preview and new remove button
            imgPreviewSelectedRefs.current[newPreviewIndex].current.style.borderWidth = '4px 4px 4px 4px'
            imgPreviewSelectedRefs.current[newPreviewIndex].current.style.borderColor = primary
            imgPreviewSelectedRefs.current[newPreviewIndex].current.style.borderStyle = 'solid'

            //! Update tracker variables
            setImagePreviewIndex(newPreviewIndex)
            
        }
    }


    //& Fetch all piece data
    useEffect(() => {

        //! Function to fetch all data relevant to the home screen
        if (amountReserved == 0 && investorCount == 0)  {
            calculateInvestmentValues()
        }
        setImagePreviewIndex(0)
        //! Set image preview refs 
        imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.border = 'none'
        imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.borderWidth = '4px 4px 4px 4px'
        imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.borderColor = primary
        imgPreviewSelectedRefs.current[imagePreviewIndex].current.style.borderStyle = 'solid'

    }, [amountReserved, investorCount]);

    return (
        <div style = {containerNavPresent}>
            <StickyNav tab = 'none' userUID = {location.state.uid} pfpSrc={location.state.pfp} disabled = {false}></StickyNav>
            <div style = {smallWhiteContainerNavPresent}>

                <h1 style = {headerTextNavPresent}>Listing Campaign</h1>

                <div style = {contentContainer}>

                    <div style = {contentOne}> 
                        
                        <div style = {largeImageContainer}> 
                            <img src={location.state.piece.images[imagePreviewIndex]} style = {imgPreviewLarge}/>
                        </div>
                        <div style = {imgSelector}>

                            <div style = {imageContainerFirst}> 
                                <img style= {imgPreviewSmall} src={location.state.piece.images[0]} alt="artImage" ref = {imgPreviewSelectedRefs.current[0]} onClick = {() => selectImagePreview(0)}/>
                            </div>
                            
                            {location.state.piece.images[1] != null ?
                                
                                <div style = {imageContainer}> 
                                    <img style= {imgPreviewSmall} src={location.state.piece.images[1]} alt="artImage" ref = {imgPreviewSelectedRefs.current[1]} onClick = {() => selectImagePreview(1)}/>
                                </div>
                                :
                                <div style = {emptyImageContainer}>
                                    <img style= {artPlaceholderSmall} src={artPlaceholder} alt="blank image — no upload yet" />
                                </div> 

                            }
                            {location.state.piece.images[2] != null ?
                                
                                <div style = {imageContainer}> 
                                    <img style= {imgPreviewSmall} src={location.state.piece.images[2]} alt="artImage" ref = {imgPreviewSelectedRefs.current[2]} onClick = {() => selectImagePreview(2)}/>
                                </div>
                                :
                                <div style = {emptyImageContainer}>
                                    <img style= {artPlaceholderSmall} src={artPlaceholder} alt="blank image — no upload yet" />
                                </div> 

                            }
                            {location.state.piece.images[3] != null ?

                                <div style = {imageContainer}> 
                                    <img style= {imgPreviewSmall} src={location.state.piece.images[2]} alt="artImage" ref = {imgPreviewSelectedRefs.current[3]} onClick = {() => selectImagePreview(3)}/>
                                </div> 
                                :
                                <div style = {emptyImageContainer}>
                                    <img style= {artPlaceholderSmall} src={artPlaceholder} alt="blank image — no upload yet" />
                                </div> 
                            }


                        </div>
                        <div style = {genreScroll}>
                            {location.state.piece.genre.map((genre, index) => (
                                <div style = {genreButtonBoxSmall}>{genre}</div> 
                            ))}
                        
                        </div>
                        <p style = {pieceDescription}><span style = {quotes}>" </span>{location.state.piece.description}<span style = {quotes}>"</span></p>
                        
                        
              
                    </div>
                    <div style = {contentTwoMetrics}>
                        <div style = {pieceFactBox}>
                            <h2 style = {subHeader}>Title</h2>
                            <h2 style = {pieceFact}>{location.state.piece.title}</h2>
                        </div>

                        <div style = {pieceFactBox}>
                            <h2 style = {subHeader} >Medium</h2>
                            <h2 style = {pieceFact}>{location.state.piece.medium}</h2>
                        </div>

                        <div style = {pieceFactBox}>
                            <h2 style = {subHeader}>Value</h2>
                            <h2 style = {pieceFact}>${location.state.piece.price}</h2>
                        </div>
                        <div style = {pieceFactBox}>
                            <h2 style = {subHeader}>Backers</h2>
                            <h2 style = {pieceFact}>{investorCount} investors</h2>
                        </div>
                        <div style = {pieceFactBox}>
                            <h2 style = {subHeader}>Status</h2>
                            <h2 style = {pieceFact}>{location.state.piece.status}</h2>
                        </div>
                    </div>
                    <div style = {contentThree}>
                        <h2 style = {subHeaderFunding}>Funding Progress</h2>
                        <div style = {graphTextAligner}> 
                            <div style = {axisMap}>
                                {axisNumbers.map((number, index) => (
                                    <div style = {yAxisStyle}>${number}</div>
                                ))}
                            </div>

                            <div style = {outerAxiis}>
                          
                                <div style = {remaining}></div>
                                {percentageReserved > 20 ? 
                                    <div style = {reserved}>{Math.round(percentageReserved)}%<br/>Funded</div> :
                                    <div style = {reserved}></div>
                                }
                                
                            </div>
                        </div>
                        <div style = {timeRemaining}>
                                <h2 style = {subHeader} >Time Remaining</h2>
                                <h2 style = {pieceFact}>This is an untimed Listing Campaign</h2>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

