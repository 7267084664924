
import bigCheckMark from '../assets/bigCheckMark.png'
// import artistAppBackground from '../assets/backgroundDesign.svg'
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, altGray, darkGray, textB} from '../styles/Colors'
import CustomButton from '../components/CustomButton';
import { useNavigate, useLocation } from 'react-router';
import { getUser } from '../database/UserFunctions'
import logoBlack from '../assets/logoBlack.png'
import {container, greenBackground, header} from "../styles/Globals";
import { bigCheckLarge, bigCheckMedium, bigCheckSmall, headerTextSubBlock, appContainer, headerSub, paragraph,  } from '../styles/ApplicationStatusStyles';
import { useEffect } from 'react';

export default function ApplicationPending({navigation}) {

  //& Have contact us button go to email 
  const contactUs = () => { 
    window.location.href = "mailto:ops@unveil.group"
  }
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    const fetchUserModel = async () => {
        let userModel = await getUser(location.state.uid);
        if(userModel.artistApplicationStatus == "accepted") {
            navigate("/ApplicationAccepted", {state: {uid: location.state.uid}});
        }
        else if (userModel.artistApplicationStatus == "rejected") {
            navigate("/ApplicationRejected", {state: {uid: location.state.uid}});
        }
    };

    fetchUserModel();
}, []);
  return(
    <div style = {container}>
         
      <div style = {header}>
          <img src = {logoBlack} style = {{width: '5%', height: '5%'}} />
          <CustomButton type={'dark'} text={"Contact Us"} callbackfunction = {() => contactUs()} height = {'5vh'} width = {'12vw'}> </CustomButton>
      </div>  

      <div style = {greenBackground}>
        <div style = {appContainer}>
          <img src = {bigCheckMark} style = {bigCheckLarge} />
          <h1 style = {headerTextSubBlock}>Thank You.</h1>
          <h2 style = {headerSub}>We look forward to reviewing your application</h2>
          <p style = {paragraph}>What next? Our team will review your application in the next few days. We will then contact you via the email address you submitted, as well as through notifications right here on our website. In the meantime, be sure to explore the art listed on our mobile platform!</p>
          <CustomButton type={'outline'} text={"Return to Home"} callbackfunction = {() => navigate('/Artist')} height = {'10%'} width = {'20%'}  mTop = {'2%'}> </CustomButton>

        </div>
      </div>
    </div>
  )
}

//^ STYLES

const loginContainer = { 
  backgroundColor: white,
  width: '70%',
  height: '65%',
  display: 'flex',
  flexDirection: 'column',
  borderWidth: '1px 1xp 1px 1px', 
  borderColor: lightGray, 
  borderStyle: 'solid',
}