import { useNavigate, Link, useResolvedPath} from "react-router-dom"
import {background, primary, secondary, orange, white, black, green, red, accentColor, lightGray, darkGray} from '../styles/Colors'
import bigCheckMark from '../assets/bigCheckMark.png'
import {greenContainer} from '../styles/Globals'
import { resetSuccessContainer, backToLoginButton, resetTitle, resetSub, bigCheckLarge, bigCheckMedium, bigCheckSmall} from "../styles/PasswordResetStyles"


export default function ResetSuccess() {

    
    const navigate = useNavigate()

    return (

 
        <div style = {greenContainer}>
          <div style = {resetSuccessContainer}>
            
            <img src = {bigCheckMark} style = {window.innerWidth > 1440 ? bigCheckLarge : window.innerWidth > 1250 ? bigCheckMedium : bigCheckSmall} />
            <h1 style = {resetTitle}>The Email has been sent.</h1>
            <p style = {resetSub}>Check your inbox for an email from ops@unveil.group and follow its instructions to reset your password</p>
            <h1 onClick = {() => navigate('/WelcomeBackLogin')} style = {backToLoginButton}>Back to Login</h1>
          </div>
        </div>

    
   
    );
}



