export const middleDiv = { 
    display: 'flex',
    minWidth: '100%',
    width: '100%',
    height: '80%',
    minHeight: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
}
export const subHeader = { 
    color: 'black',
    fontFamily: 'PPNeueMontrealBold',
    fontSize: '3vh',

}
export const paragraph = { 
    color: 'black',
    fontFamily: 'PPNeueMontrealMedium',
    fontSize: '2vh',
    lineHeight: '160%',
    textAlign: 'center',
    marginTop: '-.5%'
}